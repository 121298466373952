import Auth from 'containers/layouts/Auth'
import NotFound from 'containers/not-found/NotFound'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

function AuthenticatedRoute({
  types = [],
  roles = [],
  features = [],
  component: C,
  ...routeProps
}) {
  const { authenticating, authenticated, user } = useContext(AuthContext)

  const hasType = () => {
    if (types.length > 0 && !types.includes(user.type)) {
      return false
    }

    return true
  }

  const hasRole = () => {
    if (roles.length > 0 && !roles.includes(user.role)) {
      return false
    }

    return true
  }

  const hasFeature = () => {
    if (features.length > 0 && !features.every(f => user.features.includes(f))) {
      return false
    }

    return true
  }

  return (
    !authenticating && (
      <Route
        {...routeProps}
        render={props =>
          authenticated ? (
            hasType() && hasRole() && hasFeature() ? (
              <Auth component={C} {...props} />
            ) : (
              <NotFound />
            )
          ) : (
            <Redirect to={`/login`} />
          )
        }
      />
    )
  )
}

export default AuthenticatedRoute
