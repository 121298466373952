import { create, update } from 'api/customers'
import FormControl from 'components/FormControl'
import LoaderButton from 'components/LoaderButton'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

const defaultValues = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  gender: '',
}
function ManageCustomerModal({ customer, show, onHide, afterSave }) {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [values, setValues] = useState()

  useEffect(() => {
    setValues({
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      gender: customer.gender,
    })
  }, [customer])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async () => {
    setLoading(true)

    const body = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      gender: values.gender,
    }

    const res = customer.id ? await update(customer.id, body) : await create(body)

    if (res.data.hasOwnProperty('errors')) {
      setErrors(res.data.errors)
      setLoading(false)
    } else {
      await afterSave()
      setErrors({})
      setValues(defaultValues)
      setLoading(false)
      onHide()
    }
  }

  if (!values) {
    return null
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>First name</Form.Label>
            <FormControl
              name='first_name'
              errors={errors}
              inputProps={{
                value: values.first_name,
                onChange: handleChange,
                placeholder: 'Alan',
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Last name</Form.Label>
            <FormControl
              name='last_name'
              errors={errors}
              inputProps={{
                value: values.last_name,
                onChange: handleChange,
                placeholder: 'Smith',
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Email</Form.Label>
            <FormControl
              name='email'
              errors={errors}
              inputProps={{
                value: values.email,
                onChange: handleChange,
                type: 'email',
                placeholder: 'example@google.com',
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Gender</Form.Label>
            <FormControl
              name='gender'
              errors={errors}
              inputProps={{
                value: values.gender,
                onChange: handleChange,
                as: 'select',
              }}
            >
              <option value=''>Please Select</option>
              <option value='female'>Female</option>
              <option value='male'>Male</option>
            </FormControl>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide} disabled={loading}>
          Close
        </Button>
        <LoaderButton
          label='Create'
          loading={loading}
          variant='primary'
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  )
}

ManageCustomerModal.propTypes = {
  customer: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  afterSave: PropTypes.func,
}

ManageCustomerModal.defaultProps = {
  customer: defaultValues,
  show: false,
  afterSave: () => {},
}

export default ManageCustomerModal
