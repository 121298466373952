import Panel from 'components/panel/Panel'
import React from 'react'
import Badge from 'react-bootstrap/Badge'
import ReactMarkdown from 'react-markdown'
import { Scrollbar } from 'react-scrollbars-custom'
import styles from './TreatmentPanel.module.scss'

function TreatmentPanel({ treatment, backgroundImg }) {
  return (
    <Panel className={styles.treatmentPanel}>
      <div
        className={styles.treatmentPanelHeader}
        style={{ backgroundImage: `url("${backgroundImg}")` }}
      >
        <h3>{treatment.name}</h3>
      </div>

      <div className={styles.treatmentPanelContent}>
        <Scrollbar style={{ height: 400 }} maximalThumbSize={2} minimalThumbSize={20}>
          <>
            <div className='mb-4'>
              <ReactMarkdown>{treatment.details}</ReactMarkdown>
            </div>

            {treatment.items.map(x => (
              <div key={x.name} className='mb-4'>
                <div>
                  <div className='mr-2'>
                    <strong style={{ fontSize: '1.2rem' }}>{x.name}</strong>
                  </div>
                  {x.alert && (
                    <Badge className='mb-2' variant='secondary'>
                      {x.alert}
                    </Badge>
                  )}
                </div>
                {x.quantity && (
                  <ReactMarkdown>{'**How Much?** ' + x.quantity}</ReactMarkdown>
                )}
                {x.when && <ReactMarkdown>{'**When?** ' + x.when}</ReactMarkdown>}
                {x.other && <ReactMarkdown>{'**General**: ' + x.other}</ReactMarkdown>}
                {x.condition && (
                  <ReactMarkdown>{'**Condition**: ' + x.condition}</ReactMarkdown>
                )}
                <hr />
              </div>
            ))}
          </>
        </Scrollbar>
      </div>
    </Panel>
  )
}

export default TreatmentPanel
