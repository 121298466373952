import { answers, get } from 'api/simple-calculators'
import heroImage from 'assets/vitaminc.png'
import FullPageLoader from 'components/FullPageLoader'
import HeroHeader from 'components/HeroHeader'
import Meter from 'components/Meter'
import NumberForm from 'components/number-form/NumberForm'
import RecommendationPanel from 'components/RecommendationPanel'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import GenderSelect from './GenderSelect'

const slug = 'vitaminc'

function VitaminC() {
  const { user } = useContext(AuthContext)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [question, setQuestion] = useState(null)
  const [result, setResult] = useState(null)

  const [mainError, setMainError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await get(slug)
      setQuestion(res.data)
      setResult(res.data.answer ?? null)
      setInitialLoading(false)
    }
    fetchData()
  }, [])

  const handleSubmit = async (val, unit) => {
    setResult(null)
    setLoading(true)

    const res = await answers(slug, { value: val, unit: unit })
    setLoading(false)
    if (!res.success || res.data.hasOwnProperty('errors')) {
      setMainError('There was error processing your results, please try again.')
    } else {
      setMainError('')

      setResult(res.data)
    }
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return user.gender ? (
    <>
      <Container fluid className='p-0 mt-n5'>
        <HeroHeader src={heroImage} text='Vitamin C' />

        <Container className='mb-5'>
          {result && <RecommendationPanel text={result.message} variant={result.color} />}

          {mainError && (
            <Alert className='mt-4' variant='danger'>
              {mainError}
            </Alert>
          )}

          <Row className='align-items-left'>
            <Col>
              <NumberForm
                label='Enter your vitamin C result'
                calculator={question}
                loading={loading}
                result={result}
                recommendationLink={false}
                units={['%']}
                onSubmit={handleSubmit}
              />
            </Col>
            <Col>
              <Card className='p-3 font-weight-bold card-shadow'>
                <p className='text-success'>Optimal result 100%</p>
              </Card>
            </Col>
            <Col className='d-none d-xl-block'></Col>
          </Row>

          {result && (
            <Row className='mt-5 mb-5'>
              <Col className='justify-content-center'>
                <Meter
                  min={question.meter_min}
                  max={question.meter_max}
                  inc={question.meter_inc}
                  optimalMin={
                    result && question.ranges.find(range => range.label === 'Optimal').min
                  }
                  optimalMax={
                    question.ranges.find(range => range.label === 'Optimal').max
                  }
                  value={result.value}
                  colorClass='vitaminC'
                  bordered={false}
                />
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      <Container fluid className='background-grey pb-5 pt-5'>
        <Container>
          <Row>
            <Col className='justify-content-center'>
              <h6 className='font-weight-bold'>
                Why should you monitor your vitamin C levels daily?
              </h6>
              <p>{question.why}</p>
            </Col>
          </Row>

          {(question.optimal_benefits || question.non_optimal_symptoms) && (
            <Row className='mt-4'>
              {question.optimal_benefits && (
                <Col xs={12} className='justify-content-center'>
                  <Card className='h-100 p-3'>
                    {result && result.range === 'Optimal' ? (
                      <h6 className='font-weight-bold text-success'>
                        Well done you’re in optimal range, you should feel these benefits:
                      </h6>
                    ) : (
                      <h6 className='font-weight-bold'>
                        If you manage to achieve a result in optimal range you should
                        start to feel these benefits:
                      </h6>
                    )}
                    <ReactMarkdown>{question.optimal_benefits}</ReactMarkdown>
                  </Card>
                </Col>
              )}
              {result && question.non_optimal_symptoms && result.range !== 'Optimal' && (
                <Col xs={12} className='justify-content-center'>
                  <Card className='h-100 p-3'>
                    <h6 className='font-weight-bold text-danger'>
                      Youre not in optimal range, here are some symptoms you could be
                      experiencing:
                    </h6>
                    <ReactMarkdown>{question.non_optimal_symptoms}</ReactMarkdown>
                  </Card>
                </Col>
              )}
            </Row>
          )}
        </Container>
      </Container>
      <Container className='mt-3'>
        {result && (
          <>
            <h5>Recommendation</h5>
            <ReactMarkdown className='font-weight-light'>
              {result.recommendation}
            </ReactMarkdown>
          </>
        )}
      </Container>
    </>
  ) : (
    <Container>
      <GenderSelect />
    </Container>
  )
}

export default VitaminC
