import React from 'react'
import styles from './FullPageLoader.module.scss'

function FullPageLoader() {
  return (
    <div className={styles.loader}>
      <div
        className='d-flex h-100 align-items-center justify-content-center'
        style={{ marginTop: '-100px' }}
      >
        <img style={{ width: '600px' }} src='/loader.gif' alt='Loader' />
      </div>
    </div>
  )
}

export default FullPageLoader
