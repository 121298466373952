import { ResponsiveLine } from '@nivo/line'
import { ResponsivePie } from '@nivo/pie'
import { get } from 'api/reporting-charts'
import preview from 'assets/analytics-preview.png'
import heroImage from 'assets/lookback.png'
import FullPageLoader from 'components/FullPageLoader'
import Panel from 'components/panel/Panel'
import ProgressBar from 'components/progress-bar/ProgressBar'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons'
import Collapse from 'react-bootstrap/Collapse'
import Form from 'react-bootstrap/Form'
import styles from './ReportingCharts.module.scss'

const refsLayer = props => {
  const sort = props.data
    .map(x => x.data.map(x => x))[0]
    .sort((a, b) => parseFloat(a.y) < parseFloat(b.y))[0]

  const max = Math.max(sort.y, sort.standard_max) * 1.1
  const yMin = getYMin(props.data)

  const width = props.width - 120

  return (
    <g>
      <rect
        y={props.yScale(max)}
        width={width}
        height={props.yScale(sort.standard_max) - props.yScale(max)}
        fill='#df767f'
      />
      <rect
        y={props.yScale(sort.standard_max)}
        width={width}
        height={props.yScale(sort.optimal_max) - props.yScale(sort.standard_max)}
        fill='#e8ba58'
      />
      <rect
        y={props.yScale(sort.optimal_max)}
        width={width}
        height={props.yScale(sort.optimal_min) - props.yScale(sort.optimal_max)}
        fill='#9acb71'
      />
      <rect
        y={props.yScale(sort.optimal_min)}
        width={width}
        height={props.yScale(sort.standard_min) - props.yScale(sort.optimal_min)}
        fill='#e8ba58'
      />
      <rect
        y={props.yScale(sort.standard_min)}
        width={width}
        height={props.yScale(yMin) - props.yScale(sort.standard_min)}
        fill='#df767f'
      />
      <svg viewBox={`0 184 ${width} 10`}>
        <path
          d='M20.5,19.788H.015A20.4,20.4,0,0,0,14.256,14.02,20.39,20.39,0,0,0,20.488.012L20.5,0V19.787Z'
          transform='translate(20.501 19.788) rotate(180)'
          fill='#001d34'
        />
      </svg>
      <svg viewBox={`-540 184 ${width} 10`}>
        <path
          d='M20.5,19.788H.015A20.4,20.4,0,0,0,14.256,14.02,20.39,20.39,0,0,0,20.488.012L20.5,0V19.787Z'
          transform='translate(20.501 19.788) rotate(270)'
          fill='#001d34'
        />
      </svg>{' '}
      q
      <svg viewBox={`-559 -64 ${width} 8`}>
        <path
          d='M20.486,19.789l0,0-.011-.01A20.388,20.388,0,0,0,14.241,5.768,20.4,20.4,0,0,0-.015,0h20.5V19.789Z'
          transform='translate(20.486 22) rotate(90)'
          fill='#001e3c'
        />
      </svg>
      <svg viewBox={`0 -84 ${width} 8`}>
        <path
          d='M20.486,19.789l0,0-.011-.01A20.388,20.388,0,0,0,14.241,5.768,20.4,20.4,0,0,0-.015,0h20.5V19.789Z'
          transform='translate(20.486 22) rotate(180)'
          fill='#001e3c'
        />
      </svg>
    </g>
  )
}

const getYMin = data => {
  const sort = data
    .map(x => x.data.map(x => x))[0]
    .sort((a, b) => parseFloat(a.y) > parseFloat(b.y))[0]

  return Math.min(sort.y, sort.standard_min) * 0.9
}

const getYMax = data => {
  const sort = data
    .map(x => x.data.map(x => x))[0]
    .sort((a, b) => parseFloat(a.y) < parseFloat(b.y))[0]

  return Math.max(sort.y, sort.standard_max) * 1.1
}

const toolTipElement = props => {
  return (
    <Panel className='p-2 px-3'>
      <div>
        <div className='mb-1 text-black'>
          <strong>{props.point.serieId}</strong>
        </div>
        <div className='mb-1 text-black'>Date: {props.point.data.x}</div>
        <div className='mb-1 text-success'>
          <strong>
            Optimal range: {props.point.data.optimal_min} - {props.point.data.optimal_max}
          </strong>
        </div>
        <div className='mb-2 text-orange'>
          <strong>
            Standard range: {props.point.data.standard_min} -{' '}
            {props.point.data.standard_max}
          </strong>
        </div>
        <div
          className={`${
            props.point.data.y >= props.point.data.optimal_min &&
            props.point.data.y <= props.point.data.optimal_max
              ? 'bg-success'
              : 'bg-orange'
          } p-1 text-center`}
        >
          Your value: {props.point.data.y}
        </div>
      </div>
    </Panel>
  )
}

const RawLineChart = ({ data, dataFilters }) => {
  const questionsToInclude = dataFilters
    ? dataFilters.filter(x => x.checked).map(x => x.question)
    : []

  let layers = []

  if (dataFilters.filter(x => x.checked).length === 1) {
    layers = [
      'grid',
      refsLayer,
      'markers',
      'axes',
      'areas',
      'crosshair',
      'lines',
      'points',
      'slices',
      'mesh',
      'legends',
    ]
  } else {
    layers = [
      'grid',
      'markers',
      'axes',
      'areas',
      'crosshair',
      'lines',
      'points',
      'slices',
      'mesh',
      'legends',
    ]
  }

  const datum = data
    .filter(x => questionsToInclude.includes(x.question))
    .map(x => {
      return {
        id: x.question,
        data: x.raw.map(y => ({
          x: y.date,
          y: y.value,
          type: y.type,
          standard_min: x.standard_min,
          standard_max: x.standard_max,
          optimal_min: x.optimal_min,
          optimal_max: x.optimal_max,
        })),
      }
    })

  return (
    <ResponsiveLine
      data={datum}
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      curve='monotoneX'
      tooltip={toolTipElement}
      yScale={{
        type: 'linear',
        min: dataFilters.filter(x => x.checked).length === 1 ? getYMin(datum) : 'auto',
        max: dataFilters.filter(x => x.checked).length === 1 ? getYMax(datum) : 'auto',
        stacked: false,
        reverse: false,
      }}
      axisLeft={null}
      yFormat=' >-.2f'
      layers={layers}
      lineWidth={5}
      pointSize={0}
      pointBorderWidth={0}
      pointLabelYOffset={-12}
      useMesh={true}
      colors={['#001e3c']}
      theme={{
        textColor: 'white',
        grid: {
          line: {
            strokeWidth: 0,
          },
        },
        axis: {
          ticks: {
            line: {
              strokeWidth: 0,
            },
          },
        },
      }}
    />
  )
}

const OptimalPie = ({ stats }) => {
  const data = []

  stats.in_optimal.map(x => data.push({ id: x, label: x, value: 1, optimal: 'optimal' }))
  stats.standard.map(x => data.push({ id: x, label: x, value: 1, optimal: 'standard' }))
  stats.outside_standard.map(x =>
    data.push({ id: x, label: x, value: 1, optimal: 'outside_standard' })
  )

  const colors = {
    optimal: '#87c856',
    standard: '#e8ae31',
    outside_standard: '#da545f',
  }
  const getColor = item => colors[item.data.optimal]

  return (
    <ResponsivePie
      colors={getColor}
      data={data}
      tooltip={props => (
        <Panel className='p-2 px-3'>
          <strong>{props.datum.label}</strong>
        </Panel>
      )}
      margin={{ top: 20, right: 20, bottom: 10, left: 20 }}
      innerRadius={0.75}
      padAngle={0.2}
      enableArcLinkLabels={false}
      activeOuterRadiusOffset={2}
      activeInnerRadiusOffset={5}
      enableArcLabels={false}
    />
  )
}

function ReportingCharts() {
  const [data, setData] = useState()
  const [dataFilters, setDataFilters] = useState([])

  useEffect(() => {
    get().then(x => {
      setDataFilters(
        x.data.chart.map((x, index) => ({ question: x.question, checked: index < 1 }))
      )
      setData(x)
    })
  }, [])

  const handleChangeDataFilter = e => {
    const { name, checked } = e.target
    const newDataFilters = Array.from(dataFilters)
    newDataFilters.map(x => (x.checked = false))
    newDataFilters[name].checked = checked
    setDataFilters(newDataFilters)
  }

  const showStats = () => {
    const optimalCount = data.data.stats.in_optimal.length
    const standardCount = data.data.stats.standard.length
    const outsideStandardCount = data.data.stats.outside_standard.length

    return optimalCount > 0 || standardCount > 0 || outsideStandardCount > 0
  }

  const showChart = () => {
    return data.data.chart.length > 0
  }

  if (!data) {
    return <FullPageLoader />
  }

  return (
    <Container fluid className='p-0 mt-n5'>
      <div
        className={`${styles.heroImage} mb-4`}
        style={{
          background: `url("${heroImage}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='h-100'>
          <Container className='h-100'>
            <div className='h-100 d-flex align-items-center justify-content-end'>
              {showStats() && (
                <Panel
                  className={`${styles.headline} p-3 px-4 bg-orange`}
                  style={{ width: '600px' }}
                >
                  {data.data.message}
                </Panel>
              )}
            </div>
          </Container>
        </div>
      </div>

      <Container>
        {showStats() && (
          <>
            <div className='row'>
              <div className='col-lg-9 mb-4'>
                <Panel className='p-1 h-100'>
                  <div className='ml-3 mt-3 mb-3'>
                    <strong>Based on your most recent results, you have:</strong>
                  </div>

                  <DataProgressBar
                    text='Results in optimal'
                    displayData={data.data.stats.in_optimal}
                    data={data}
                    variant='green'
                  />
                  <DataProgressBar
                    text='Results in standard range'
                    displayData={data.data.stats.standard}
                    data={data}
                    variant='orange'
                  />
                  <DataProgressBar
                    text='Results outside standard range'
                    displayData={data.data.stats.outside_standard}
                    data={data}
                    variant='red'
                  />
                </Panel>
              </div>

              <div className='col-lg-3 mb-4'>
                <Panel className='p-1 h-100'>
                  <div className='ml-3 mt-3'>
                    <strong>Your Progress</strong>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='w-100' style={{ height: '200px' }}>
                      <OptimalPie stats={data.data.stats} />
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
          </>
        )}

        {showChart() ? (
          <>
            <div className='row'>
              <div className='col-lg-12'>
                <Panel className='p-3 bg-dark-blue'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      Take a look at how results have changed over time...
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-9 mb-4'>
                      <div className='w-100' style={{ height: '440px' }}>
                        <RawLineChart data={data.data.chart} dataFilters={dataFilters} />
                      </div>
                    </div>

                    <div className='col-lg-3 mb-4'>
                      <div className='d-flex flex-wrap'>
                        {dataFilters.map((x, index) => (
                          <Form.Group
                            key={x.question}
                            className='mb-0'
                            controlId={x.question}
                            style={{ minWidth: '200px' }}
                          >
                            <Form.Check
                              type='radio'
                              name={index}
                              label={x.question}
                              checked={x.checked}
                              onChange={handleChangeDataFilter}
                            />
                          </Form.Group>
                        ))}
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
          </>
        ) : (
          <div className='mb-3'>
            <Panel className='background-secondary p-4 mb-5 mt-5'>
              <strong>
                Once you've entered some more results you'll be able to visualise your
                data here.
              </strong>
            </Panel>

            <div className='text-center'>
              <img
                style={{
                  width: '100%',
                  maxWidth: '1200px',
                  filter: 'blur(3px)',
                  opacity: '0.15',
                }}
                src={preview}
                alt='Preview'
              />
            </div>
          </div>
        )}
      </Container>
    </Container>
  )
}

function DataProgressBar({ text, displayData, data, variant }) {
  const [open, setOpen] = useState(false)

  return (
    <div className='p-2 px-3'>
      <div className='cursor-pointer' onClick={() => setOpen(!open)}>
        <ProgressBar
          percentageDisplay={displayData.length}
          text={text}
          percentage={
            (displayData.length /
              (data.data.stats.in_optimal.length +
                data.data.stats.standard.length +
                data.data.stats.outside_standard.length)) *
            100
          }
          textWidth='280px'
          variant={variant}
        >
          {displayData.length > 0 && (
            <div className='d-flex justify-content-end align-items-center h-100 w-100 pr-2 text-white'>
              {open ? <ChevronUp /> : <ChevronDown />}
            </div>
          )}
        </ProgressBar>
      </div>

      <Collapse in={open}>
        <div className='pl-1'>
          <small>{displayData.join(', ')}</small>
        </div>
      </Collapse>
    </div>
  )
}

export default ReportingCharts
