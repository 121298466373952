import { answers, get } from 'api/simple-calculators'
import heroImage from 'assets/zinc.png'
import ChoiceForm from 'components/choice-form/ChoiceForm'
import FullPageLoader from 'components/FullPageLoader'
import HeroHeader from 'components/HeroHeader'
import RecommendationPanel from 'components/RecommendationPanel'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import GenderSelect from './GenderSelect'

const slug = 'zinc'

function Zinc() {
  const { user } = useContext(AuthContext)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [question, setQuestion] = useState(null)
  const [result, setResult] = useState(null)

  const [mainError, setMainError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await get(slug)
      setQuestion(res.data)
      setResult(res.data.answer ?? null)
      setInitialLoading(false)
    }
    fetchData()
  }, [])

  const handleSubmit = async val => {
    setResult(null)
    setLoading(true)

    const res = await answers(slug, { value: val })
    setLoading(false)
    if (!res.success || res.data.hasOwnProperty('errors')) {
      setMainError({})
      setMainError('There was error processing your results, please try again.')
    } else {
      setMainError('')
      setResult(res.data)
    }
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return user.gender ? (
    <>
      <Container fluid className='p-0 mt-n5'>
        <HeroHeader src={heroImage} text='Zinc' />

        <Container>
          {result && <RecommendationPanel text={result.message} variant={result.color} />}

          <ChoiceForm
            options={question.options}
            question={question.question}
            loading={loading}
            onSubmit={e => {
              handleSubmit(e)
            }}
          />

          <Row className='mt-5 mb-5'>
            <Col className='justify-content-center'>
              <h6>Why is Zinc an essential mineral?</h6>
              <p className='font-weight-light'>{question.why}</p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className='background-grey pb-5 pt-5'>
        <Container>
          {mainError && (
            <Alert className='mt-4' variant='danger'>
              {mainError}
            </Alert>
          )}

          {result && (
            <Card className='p-3'>
              <h6 className={`text-${result.color}`}>Your Zinc status</h6>
              <p className='font-weight-light'>{result.recommendation}</p>
            </Card>
          )}
        </Container>
      </Container>
    </>
  ) : (
    <Container>
      <GenderSelect />
    </Container>
  )
}

export default Zinc
