import ManageTreatments from 'components/ManageTreatments'
import React from 'react'
import { Container } from 'react-bootstrap'

function WhiteBloodCells() {
  return (
    <Container>
      <h1 className='mb-4'>Treatments</h1>

      <ManageTreatments slug='rbc' />
    </Container>
  )
}

export default WhiteBloodCells
