import Panel from 'components/panel/Panel'
import React from 'react'
import Container from 'react-bootstrap/Container'

function SystemUserDashboard() {
  return (
    <Container>
      <div>
        <Panel>Content</Panel>
      </div>
    </Container>
  )
}

export default SystemUserDashboard
