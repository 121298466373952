import { get, update } from 'api/simple-calculators'
import FormControl from 'components/FormControl'
import FormControlMarkdown from 'components/FormControlMarkdown'
import FullPageLoader from 'components/FullPageLoader'
import LoaderButton from 'components/LoaderButton'
import Panel from 'components/panel/Panel'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

const slug = 'vitamind'

function VitaminD() {
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [values, setValues] = useState()
  const [errors, setErrors] = useState({})
  const [saved, setSaved] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true)

      const res = await get(slug)

      setValues({
        eu_optimal_min: res.data.ranges.eu.optimal_min,
        eu_optimal_max: res.data.ranges.eu.optimal_max,
        eu_standard_min: res.data.ranges.eu.standard_min,
        eu_standard_max: res.data.ranges.eu.standard_max,
        us_optimal_min: res.data.ranges.us.optimal_min,
        us_optimal_max: res.data.ranges.us.optimal_max,
        us_standard_min: res.data.ranges.us.standard_min,
        us_standard_max: res.data.ranges.us.standard_max,
        recommendations: res.data.recommendations,
      })

      setInitialLoading(false)
    }

    fetchData()
  }, [])

  const handleChange = event => {
    setSaved(false)
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleChangeRecommendation = (index, event) => {
    const recs = Array.from(values.recommendations)
    recs[index].rec = event.target.value
    setValues({ ...values, recommendations: recs })
  }

  const getRangeText = (min, max, unit) => {
    return max === null ? `${min}+ ${unit}` : `${min} - ${max} ${unit}`
  }

  const handleSubmit = async e => {
    e.preventDefault()

    setLoading(true)
    setErrors({})
    setSaved(false)

    const body = {
      eu_optimal_min: values.eu_optimal_min,
      eu_optimal_max: values.eu_optimal_max,
      eu_standard_min: values.eu_standard_min,
      eu_standard_max: values.eu_standard_max,
      us_optimal_min: values.us_optimal_min,
      us_optimal_max: values.us_optimal_max,
      us_standard_min: values.us_standard_min,
      us_standard_max: values.us_standard_max,
      recommendations: values.recommendations,
    }

    const res = await update(slug, body)

    if (res.data.hasOwnProperty('errors')) {
      setErrors(res.data.errors)
      setLoading(false)
    } else {
      setErrors({})
      setLoading(false)
      setSaved(true)
    }
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return (
    <Container>
      <h1 className='mb-4'>Vitamin D Calculator</h1>

      <Panel className='p-4'>
        <Form>
          <h3>Display Ranges</h3>
          <div className='mb-4'>
            These are displayed to the customer as the optimal and standard ranges.
          </div>
          <div className='d-flex flex-wrap mb-4'>
            <div className='flex-grow-1'>
              <h5>EU Ranges (Standard International); nmol/L</h5>
              <div className='d-flex'>
                <Form.Group className='mb-4 mr-2 w-100'>
                  <Form.Label>Optimal Min</Form.Label>
                  <FormControl
                    name='eu_optimal_min'
                    errors={errors}
                    inputProps={{
                      value: values.eu_optimal_min,
                      onChange: handleChange,
                    }}
                  />
                </Form.Group>

                <Form.Group className='mb-4 mr-2 w-100'>
                  <Form.Label>Optimal Max</Form.Label>
                  <FormControl
                    name='eu_optimal_max'
                    errors={errors}
                    inputProps={{
                      value: values.eu_optimal_max,
                      onChange: handleChange,
                    }}
                  />
                </Form.Group>
              </div>

              <div className='d-flex'>
                <Form.Group className='mb-4 mr-2 w-100'>
                  <Form.Label>Standard Min</Form.Label>
                  <FormControl
                    name='eu_standard_min'
                    errors={errors}
                    inputProps={{
                      value: values.eu_standard_min,
                      onChange: handleChange,
                    }}
                  />
                </Form.Group>

                <Form.Group className='mb-4 mr-2 w-100'>
                  <Form.Label>Standard Max</Form.Label>
                  <FormControl
                    name='eu_standard_max'
                    errors={errors}
                    inputProps={{
                      value: values.eu_standard_max,
                      onChange: handleChange,
                    }}
                  />
                </Form.Group>
              </div>
            </div>

            <div className='flex-grow-1'>
              <h5>Standard US Ranges; ng/ml</h5>
              <div className='d-flex'>
                <Form.Group className='mb-4 mr-2 w-100'>
                  <Form.Label>Optimal Min</Form.Label>
                  <FormControl
                    name='us_optimal_min'
                    errors={errors}
                    inputProps={{
                      value: values.us_optimal_min,
                      onChange: handleChange,
                    }}
                  />
                </Form.Group>

                <Form.Group className='mb-4 mr-2 w-100'>
                  <Form.Label>Optimal Max</Form.Label>
                  <FormControl
                    name='us_optimal_max'
                    errors={errors}
                    inputProps={{
                      value: values.us_optimal_max,
                      onChange: handleChange,
                    }}
                  />
                </Form.Group>
              </div>

              <div className='d-flex'>
                <Form.Group className='mb-4 mr-2 w-100'>
                  <Form.Label>Standard Min</Form.Label>
                  <FormControl
                    name='us_standard_min'
                    errors={errors}
                    inputProps={{
                      value: values.us_standard_min,
                      onChange: handleChange,
                    }}
                  />
                </Form.Group>

                <Form.Group className='mb-4 mr-2 w-100'>
                  <Form.Label>Standard Max</Form.Label>
                  <FormControl
                    name='us_standard_max'
                    errors={errors}
                    inputProps={{
                      value: values.us_standard_max,
                      onChange: handleChange,
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          <div>
            <h3>Recomendations</h3>
            <div className='mb-4'>
              Then value entered the customer will be matched to one of the below
              recommendations. The customer will only see the recommendation text not the
              ranges. The only ranges displayed are the above "display ranges".
            </div>

            {values.recommendations.map((x, index) => (
              <Form.Group className='mb-4' key={`rec-${index}`}>
                <FormControlMarkdown
                  label={`${x.label} (${getRangeText(
                    x.eu_min,
                    x.eu_max,
                    'nmol/L'
                  )} / ${getRangeText(x.us_min, x.us_max, 'ng/ml')})`}
                  value={values.rec_below_standard}
                  formControlProps={{
                    name: `recommendations.${index}.rec`,
                    errors: errors,
                    inputProps: {
                      maxLength: 255,
                      value: x.rec,
                      onChange: e => {
                        handleChangeRecommendation(index, e)
                      },
                      as: 'textarea',
                      rows: 2,
                    },
                  }}
                />
              </Form.Group>
            ))}
          </div>
        </Form>
      </Panel>

      <div className='mt-4 d-flex justify-content-end'>
        {Object.keys(errors).length > 0 && (
          <Alert className='mr-3' variant='danger'>
            Failed to save
          </Alert>
        )}

        {saved && (
          <Alert className='mr-3' variant='success'>
            Saved
          </Alert>
        )}

        <div>
          <LoaderButton
            label='Save'
            type='submit'
            loading={loading}
            variant='primary'
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Container>
  )
}

export default VitaminD
