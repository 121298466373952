import React from 'react'
import { Container } from 'react-bootstrap'
import styles from './HeroHeader.module.scss'

function HeroHeader({ src, text }) {
  return (
    <div
      className={`${styles.hero} mb-4`}
      style={{
        background: `url("${src}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container>
        <h1 className='text-white font-weight-bold'>{text}</h1>
      </Container>
    </div>
  )
}

export default HeroHeader
