import { answers, get } from 'api/simple-calculators'
import smallbanner3 from 'assets/smallbanner3.png'
import vitd from 'assets/vitd.png'
import FormControl from 'components/FormControl'
import FullPageLoader from 'components/FullPageLoader'
import LoaderButton from 'components/LoaderButton'
import Panel from 'components/panel/Panel'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import ReactMarkdown from 'react-markdown'
import GenderSelect from './GenderSelect'
import styles from './VitaminD.module.scss'

const slug = 'vitamind'

function VitaminD() {
  const { user } = useContext(AuthContext)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [displayForm, setDisplayForm] = useState(true)

  const [question, setQuestion] = useState()
  const [values, setValues] = useState({ type: 'eu', value: '' })

  const [errors, setErrors] = useState({})
  const [mainError, setMainError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await get(slug)
      setQuestion(res)
      setDisplayForm(!res.data.hasOwnProperty('answer'))
      setInitialLoading(false)
    }

    fetchData()
  }, [])

  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    const res = await answers('vitamind', values)
    if (!res.success || res.data.hasOwnProperty('errors')) {
      if (res.data.hasOwnProperty('errors')) {
        setErrors(res.data.errors)
        setMainError('')
      } else {
        setMainError({})
        setMainError('There was error processing your results, please try again.')
      }
    } else {
      setQuestion(await get(slug))
      setDisplayForm(false)
      setErrors({})
      setMainError('')
    }

    setLoading(false)
  }

  const renderAnswers = () => {
    return (
      <>
        <Panel className={`${styles.panelBlue} mb-5`} backgroundImg={smallbanner3}>
          <h2 className={styles.title}>Your Results</h2>

          <div>
            <div>
              <div className='mb-4'>
                Please see below for your recommendation based on your results.
              </div>

              <div className='d-flex flex-column flex-sm-row'>
                <Button
                  variant='secondary'
                  className='w-100 mb-3 py-3'
                  onClick={setDisplayForm}
                >
                  Enter New Results
                </Button>
              </div>
            </div>
          </div>
        </Panel>

        <Panel className={styles.panelBlue}>
          <div>
            <div className={styles.title}>Recommendation</div>

            <ReactMarkdown>{question.data.answer.recommendation}</ReactMarkdown>

            <table className='mt-3'>
              <tbody>
                <tr>
                  <td className='pr-3'>Standard Lab Range</td>
                  <td>
                    {question.data.answer.ranges.standard_min} -{' '}
                    {question.data.answer.ranges.standard_max}
                  </td>
                </tr>
                <tr>
                  <td>Optimal Range</td>
                  <td>
                    {question.data.answer.ranges.optimal_min} -{' '}
                    {question.data.answer.ranges.optimal_max}
                  </td>
                </tr>
                <tr>
                  <td>You Answered</td>
                  <td>{question.data.answer.value}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Panel>
      </>
    )
  }

  const renderForm = () => {
    return (
      <>
        <Panel className={`${styles.panelBlue} mb-5`}>
          <div>
            <div className={styles.title}>Next Steps</div>
            <div className='mb-3'>
              Vitamin D is a primary defender of your body’s immunity. It is vital that
              your Vitamin D levels are optimal at all times.
            </div>
            <div>Enter your results below to find out your recommendations.</div>
          </div>
        </Panel>

        <Form onSubmit={handleSubmit}>
          <Panel className={styles.panel} backgroundImg={vitd}>
            <Form.Group className='mb-4'>
              <div className={`${styles.form} d-flex flex-wrap mt-3`}>
                <div className='flex-grow-1 ml-5 mr-5 mb-3'>
                  <Form.Label>Results Type</Form.Label>
                  <FormControl
                    name='type'
                    errors={errors}
                    errorClassName='text-white'
                    inputProps={{
                      value: values.type,
                      onChange: handleChange,
                      as: 'select',
                    }}
                  >
                    <option value='eu'>Europe (nmol/L)</option>
                    <option value='us'>USA (ng/ml)</option>
                  </FormControl>
                </div>

                <div className='flex-grow-1 ml-5 mr-5 mb-3'>
                  <Form.Label>Results Value</Form.Label>
                  <FormControl
                    name='value'
                    errors={errors}
                    errorClassName='text-white'
                    inputProps={{
                      placeholder: 'Enter your results',
                      onChange: handleChange,
                      maxLength: 5,
                    }}
                  />
                </div>
              </div>
            </Form.Group>
          </Panel>

          {mainError && (
            <Alert className='mt-4' variant='danger'>
              {mainError}
            </Alert>
          )}

          {!initialLoading && (
            <div className='text-right'>
              <LoaderButton
                label='Calculate'
                className={`${styles.lightOrangeButton} mt-5`}
                loading={loading}
                variant='primary'
                size='lg'
                type='submit'
              />
            </div>
          )}
        </Form>
      </>
    )
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return (
    <Container>
      {user.gender ? (
        <>{displayForm ? renderForm() : renderAnswers()}</>
      ) : (
        <GenderSelect />
      )}
    </Container>
  )
}

export default VitaminD
