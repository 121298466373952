import React from 'react'
import { InfoCircle } from 'react-bootstrap-icons'
import Alert from 'react-bootstrap/Alert'
import PropTypes from 'prop-types'

function SelfResponsible({ alertProps }) {
  return (
    <Alert variant='orange' {...alertProps}>
      <div className='d-flex align-items-center'>
        <div style={{ width: '100px' }}>
          <InfoCircle size={30} />
        </div>
        <div>
          <div className='mb-2'>
            It is important that you are fully self responsible in your choices and that
            you are aware of personal sensitivities, intolerances, allergies or
            dietary needs before choosing your treatment recommendations, whether
            supplemental or nutritional.
          </div>

          <div>
            <strong>
              If a recommended supplement or nutritional comes up more than once, this
              does NOT mean it has to be doubled or taken twice.
            </strong>
          </div>
        </div>
      </div>
    </Alert>
  )
}

SelfResponsible.propTypes = {
  alertProps: PropTypes.object,
}

SelfResponsible.defaultProps = {
  alertProps: {},
}

export default SelfResponsible
