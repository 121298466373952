import config from 'config'
import { getJsonHeaders } from './helpers'

export async function list(limit = 15, page = 1) {
  let url = `/iapi/customers?per_page=${limit}&page=${page}`
  const result = await fetch(config.API_URL + url)
  return await result.json()
}

export async function get(id) {
  const result = await fetch(config.API_URL + `/iapi/customers/${id}`)
  return await result.json()
}

export async function create(body) {
  const res = await fetch(config.API_URL + `/iapi/customers`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 201,
    data: await res.json(),
  }
}

export async function update(id, body) {
  const res = await fetch(config.API_URL + `/iapi/customers/${id}`, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}

export async function deleteUser(id) {
  const res = await fetch(config.API_URL + `/iapi/customers/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
  })

  return res.status === 204
}
