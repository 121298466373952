import React from 'react'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

function Loader({ data }) {
  return (
    <Container className='h-100 d-flex align-items-center justify-content-center'>
      <Spinner animation='border' />
    </Container>
  )
}

export default Loader
