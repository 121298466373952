import FormControl from 'components/FormControl'
import Panel from 'components/panel/Panel'
import Tooltip from 'components/Tooltip'
import PropTypes from 'prop-types'
import React from 'react'
import { InfoCircle } from 'react-bootstrap-icons'
import Form from 'react-bootstrap/Form'
import styles from './CBCCalculator.module.scss'

const disabledFields = ['neulymratio']

function CBCCalculator({ questions, values, errors, setValues, title, backgroundImg }) {
  const handleChange = e => {
    const { name, value } = e.target
    const newValues = { ...values, [name]: value }

    if (name === 'neutrophils' || name === 'lymphocytes') {
      const neu = parseFloat(newValues.neutrophils)
      const lym = parseFloat(newValues.lymphocytes)

      if (neu && lym) {
        newValues.neulymratio = parseFloat((newValues.neulymratio = neu / lym)).toFixed(2)
      } else {
        delete newValues.neulymratio
      }
    }

    setValues(newValues)
  }

  const getValue = slug => {
    const val = values[slug]
    return val === undefined ? '' : val
  }

  return (
    <Panel className={styles.panel} backgroundImg={backgroundImg}>
      <h2 className={styles.title}>{title}</h2>

      <div className={styles.formGrid}>
        {questions.data.map(x => (
          <Form.Group key={x.slug} className='mb-4'>
            <div className='d-flex'>
              {x.tooltip && (
                <div className='mr-2' style={{ marginTop: '-2px', width: '20px' }}>
                  <Tooltip label={x.tooltip}>
                    <InfoCircle />
                  </Tooltip>
                </div>
              )}
              <Form.Label>{x.question}</Form.Label>
            </div>

            <div className='d-flex'>
              <FormControl
                name={x.slug}
                errors={errors}
                errorClassName='text-white'
                inputProps={{
                  disabled: disabledFields.includes(x.slug),
                  value: getValue(x.slug),
                  onChange: handleChange,
                }}
              />
            </div>
          </Form.Group>
        ))}
      </div>
    </Panel>
  )
}

CBCCalculator.propTypes = {
  questions: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  setValues: PropTypes.func,
  title: PropTypes.string,
  backgroundImg: PropTypes.string,
}

CBCCalculator.defaultProps = {
  title: '',
}

export default CBCCalculator
