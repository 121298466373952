import { updateMe } from 'api/auth'
import FormControl from 'components/FormControl'
import LoaderButton from 'components/LoaderButton'
import Panel from 'components/panel/Panel'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useState } from 'react'
import Form from 'react-bootstrap/Form'
import styles from './GenderSelect.module.scss'

function GenderSelect() {
  const { setUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [gender, setGender] = useState('')
  const [errors, setErrors] = useState({})

  const handleChange = event => {
    setGender(event.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    const res = await updateMe({ gender: gender })

    if (res.data.hasOwnProperty('errors')) {
      setErrors(res.data.errors)
      setLoading(false)
    } else {
      setUser(res.data)
      setErrors({})
      setLoading(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Panel className={`${styles.panelBlue} mb-5`}>
        <div>
          <div className={styles.title}>Information Required</div>
          <div className='mb-3'>
            To provide you with the correct treatments you must first select your medical
            gender.
          </div>
          <div>
            <strong>Why do I need select a gender?</strong> The optimal ranges expected
            for blood result data will differ slightly based on your medical gender. you
          </div>
        </div>

        <div className='mt-4'>
          <Form.Group>
            <FormControl
              name='gender'
              errors={errors}
              inputProps={{
                value: gender,
                onChange: handleChange,
                as: 'select',
              }}
            >
              <option value=''>Please Select</option>
              <option value='female'>Female</option>
              <option value='male'>Male</option>
            </FormControl>
          </Form.Group>
        </div>
      </Panel>

      <div className='text-right'>
        <LoaderButton
          label='Submit'
          className={`${styles.lightOrangeButton}`}
          loading={loading}
          variant='primary'
          size='lg'
          type='submit'
        />
      </div>
    </Form>
  )
}

export default GenderSelect
