import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import FormControlMarkdown from './FormControlMarkdown'
import LoaderButton from './LoaderButton'
import Panel from './panel/Panel'

function RangeForm({ range, index, onChange, fields }) {
  return (
    <Panel className='p-4 mb-4'>
      <Row>
        {fields
          .filter(f => f.type !== 'markdown')
          .map((f, i) => (
            <Col key={`field-${i}`}>
              <Form.Label>{f.label}</Form.Label>
              {f.type === 'color' ? (
                <Form.Control
                  name={`${f.name}[${index}]`}
                  value={range[f.name]}
                  onChange={e => onChange(index, f.name, e.target.value)}
                  as='select'
                >
                  <option value='red'>Red</option>
                  <option value='yellow'>Yellow</option>
                  <option value='green'>Green</option>
                  <option value='blue'>Blue</option>
                </Form.Control>
              ) : (
                <Form.Control
                  name={`${f.name}[${index}]`}
                  value={range[f.name] ?? ''}
                  type={f.type}
                  onChange={e => onChange(index, f.name, e.target.value)}
                ></Form.Control>
              )}
            </Col>
          ))}
      </Row>

      {fields
        .filter(f => f.type === 'markdown')
        .map((f, i) => (
          <Form.Group key={`md-field-${i}`}>
            <FormControlMarkdown
              label={f.label}
              value={range[f.name]}
              formControlProps={{
                name: `${f.name}[${index}]`,
                errors: {},
                inputProps: {
                  maxLength: 1023,
                  value: range[f.name],
                  onChange: e => {
                    onChange(index, f.name, e.target.value)
                  },
                  as: 'textarea',
                  rows: 4,
                },
              }}
            ></FormControlMarkdown>
          </Form.Group>
        ))}
    </Panel>
  )
}

function ManageRangeCalculator({ initialData, loading, onSubmit, fields, rangeFields }) {
  const [state, setState] = useState(initialData)

  const handleChange = e => {
    const { name, value } = e.target
    setState(state => ({ ...state, [name]: value }))
  }

  const handleChangeRange = (index, name, value) => {
    setState(state => ({
      ...state,
      ranges: state.ranges.map((r, i) => (i === index ? { ...r, [name]: value } : r)),
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(state)
  }

  return (
    <>
      <Form>
        {fields
          .filter(f => f.type === 'markdown')
          .map((f, i) => (
            <Form.Group key={`form-group-${i}`}>
              <FormControlMarkdown
                label={f.label}
                value={state[f.name]}
                formControlProps={{
                  name: f.name,
                  errors: {},
                  inputProps: {
                    maxLength: 1023,
                    value: state[f.name],
                    onChange: handleChange,
                    as: 'textarea',
                    rows: 4,
                  },
                }}
              ></FormControlMarkdown>
            </Form.Group>
          ))}

        <Row>
          {fields
            .filter(f => f.type !== 'markdown')
            .map((f, i) => (
              <Col key={`field_${i}`}>
                <Form.Group>
                  <Form.Label>{f.label}</Form.Label>
                  <Form.Control
                    name={f.name}
                    type={f.type}
                    value={state[f.name] ?? 0}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
              </Col>
            ))}
        </Row>

        <h3>Ranges</h3>
        {state.ranges.map((range, index) => (
          <RangeForm
            range={range}
            index={index}
            fields={rangeFields}
            key={`range[${index}]`}
            onChange={handleChangeRange}
          />
        ))}

        <div>
          <LoaderButton
            label='Save'
            type='submit'
            loading={loading}
            variant='primary'
            onClick={handleSubmit}
          />
        </div>
      </Form>
    </>
  )
}

export default ManageRangeCalculator
