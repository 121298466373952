import banner1 from 'assets/banner1.png'
import feature1 from 'assets/feature1.png'
import feature2 from 'assets/feature2.png'
import Panel from 'components/panel/Panel'
import React from 'react'
import { Button } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import styles from './Dashboard.module.scss'

function CustomerDashboard() {
  return (
    <Container>
      <div className='row'>
        <div className='col mb-4'>
          <Panel className={styles.mainPanel} backgroundImg={banner1}>
            <div className={styles.title}>Choose your calculator</div>
            <div className='row'>
              <div className='col-md-6 col-lg-5 mb-4'>
                <Panel className={styles.subPanel}>
                  <div className={styles.title}>CBC</div>
                  <Button as={Link} to='/cbc' className={styles.gradientButton}>
                    Get Started
                  </Button>
                </Panel>
              </div>
              <div className='col-md-6 col-lg-5 offset-lg-2'>
                <Panel className={styles.subPanel}>
                  <div className={styles.title}>Vitamin D</div>
                  <Button as={Link} to='/vitamin-d' className={styles.gradientButton}>
                    Get Started
                  </Button>
                </Panel>
              </div>
            </div>
          </Panel>
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col-md-6 mb-4'>
          <Link to='/treatments/nutritional' style={{ textDecoration: 'none' }}>
            <Panel className={styles.featurePanel} backgroundImg={feature1}>
              <div className={styles.title}>Nutritional recommendations</div>
            </Panel>
          </Link>
        </div>

        <div className='col-md-6'>
          <Link to='/treatments/supplements' style={{ textDecoration: 'none' }}>
            <Panel className={styles.featurePanel} backgroundImg={feature2}>
              <div className={styles.title}>Supplement recommendations</div>
            </Panel>
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default CustomerDashboard
