import React from 'react'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

function LoaderButton({ label, loading, ...props }) {
  return (
    <Button disabled={loading} {...props}>
      {loading ? (
        <Spinner as='span' animation='border' size='sm' />
      ) : (
        <span>{label}</span>
      )}
    </Button>
  )
}

export default LoaderButton
