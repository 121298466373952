import React from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'

function FormControl({
  name,
  inputProps,
  errors,
  errorPrefix,
  errorClassName,
  children,
}) {
  return (
    <div>
      <Form.Control name={name} {...inputProps} isInvalid={errors.hasOwnProperty(name)}>
        {children}
      </Form.Control>
      {errors.hasOwnProperty(`${errorPrefix}${name}`) && (
        <Form.Text className={errorClassName}>
          {errors[`${errorPrefix}${name}`]}
        </Form.Text>
      )}
    </div>
  )
}

FormControl.propTypes = {
  errorClassName: PropTypes.string,
  errorPrefix: PropTypes.string,
}

FormControl.defaultProps = {
  errorClassName: 'text-danger',
  errorPrefix: '',
}

export default FormControl
