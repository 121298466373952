import { listCategoryItems } from 'api/user-treatments'
import banner2 from 'assets/banner2.png'
import banner3 from 'assets/banner3.png'
import FullPageLoader from 'components/FullPageLoader'
import Panel from 'components/panel/Panel'
import SelfResponsible from 'components/SelfResponsible'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge'
import ReactMarkdown from 'react-markdown'
import Masonry from 'react-masonry-css'
import { useParams } from 'react-router-dom'
import styles from './TreatmentsByCategory.module.scss'

const getBanner = category => {
  const banners = {
    supplements: banner2,
    nutritional: banner3,
  }

  return banners.hasOwnProperty(category) ? banners[category] : banner2
}

function TreatmentsByCategory() {
  const { category } = useParams()
  const [treatments, setTreatments] = useState()

  useEffect(() => {
    const fetchData = async () => {
      setTreatments()
      const res = await listCategoryItems(category)
      setTreatments(res)
    }

    fetchData()
  }, [category])

  const getNoTreatmentMessage = () => {
    switch (category) {
      case 'supplements':
        return (
          <>
            Consider maintaining your vitamin therapy - especially adrenal supporting
            herbs,  zinc, vitamin C, vitamin D and omega 369 oils and an organic
            multivitamin and mineral supplement, in order to maintain the great health you
            have achieved and want to continue achieving.
          </>
        )
      case 'nutritional':
        return (
          <>
            Well done - continue eating using the lessons you learnt in the Super Immunity
            30 days course. Eat a low glycemic index, Paleo Blood type foodplan with a
            good focus on an organic rainbow selection of vegetables and fruit food plan.
          </>
        )
      default:
        return (
          <>
            None of the treatments we have recommended contain any{' '}
            {category.replace(/s$/, '')} recommendations.
          </>
        )
    }
  }

  if (!treatments) {
    return <FullPageLoader />
  }

  return (
    <Container>
      <SelfResponsible alertProps={{ className: 'mb-4' }} />

      <Panel className={styles.panel}>
        <div
          className={styles.panelHeader}
          style={{ backgroundImage: `url("${getBanner(category)}")` }}
        >
          <h3>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
        </div>

        <div className={styles.panelContent}>
          {treatments.data.length === 0 ? (
            <div className='mt-5'>
              {treatments.meta.has_answered ? (
                <>{getNoTreatmentMessage()}</>
              ) : (
                <>
                  We haven't currently got any treatment recommendations for you. Enter
                  your CBC results to check for any new treatment recommendations.
                </>
              )}
            </div>
          ) : (
            <div className='mb-4'>
              Below is a combined list of all {category.replace(/s$/, '')} recommendations
              from your recommended treatments.
            </div>
          )}

          <Masonry
            breakpointCols={{
              default: 2,
              1400: 1,
            }}
            className={styles.masonryGrid}
            columnClassName={styles.masonryGridCol}
          >
            {treatments.data.map((x, index) => (
              <div key={index}>
                <div>
                  <h3 className={`${styles.title}`}>{x.name}</h3>
                </div>

                <div className='d-flex flex-wrap mb-2'>
                  <div>
                    <Badge variant='secondary' className='m-1'>
                      {x.treatment_name}
                    </Badge>
                  </div>

                  {x.alert && (
                    <div>
                      <Badge variant='secondary'>{x.alert}</Badge>
                    </div>
                  )}
                </div>
                {x.quantity && (
                  <ReactMarkdown>{'**How much?** ' + x.quantity}</ReactMarkdown>
                )}
                {x.when && <ReactMarkdown>{'**When?** ' + x.when}</ReactMarkdown>}
                {x.other && <ReactMarkdown>{'**General**: ' + x.other}</ReactMarkdown>}
                {x.condition && (
                  <ReactMarkdown>{'**Condition**: ' + x.condition}</ReactMarkdown>
                )}
              </div>
            ))}
          </Masonry>
        </div>
      </Panel>
    </Container>
  )
}

export default TreatmentsByCategory
