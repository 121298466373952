import { get, update } from 'api/simple-calculators'
import FullPageLoader from 'components/FullPageLoader'
import ManageRangeCalculator from 'components/ManageRangeCalculator'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'

const slug = 'temperature'

function Temperature() {
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [values, setValues] = useState()
  const [errors, setErrors] = useState({})
  const [saved, setSaved] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true)

      const res = await get(slug)

      setValues(res.data)
      setInitialLoading(false)
    }

    fetchData()
  }, [])

  const handleSubmit = async values => {
    setLoading(true)
    setErrors({})
    setSaved(false)
    const res = await update(slug, values)

    if (res.data.hasOwnProperty('errors')) {
      setErrors(res.data.errors)
      setLoading(false)
    } else {
      setErrors({})
      setLoading(false)
      setSaved(true)
    }
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return (
    <Container>
      <h1 className='mb-4'>Temperature Calculator</h1>

      <ManageRangeCalculator
        initialData={values}
        loading={loading}
        onSubmit={handleSubmit}
        fields={[
          { label: 'Why Monitor?', name: 'why', type: 'markdown' },
          { label: 'Celsius Min', name: 'meter_min_celsius', type: 'number' },
          { label: 'Celsius Max', name: 'meter_max_celsius', type: 'number' },
          { label: 'Celsius Increment', name: 'meter_inc_celsius', type: 'number' },
          { label: 'Fahrenheit Min', name: 'meter_min_fahrenheit', type: 'number' },
          { label: 'Fahrenheit Max', name: 'meter_max_fahrenheit', type: 'number' },
          { label: 'Fahrenheit Increment', name: 'meter_inc_fahrenheit', type: 'number' },
        ]}
        rangeFields={[
          { label: 'Label', name: 'label', type: 'text' },
          { label: 'Celsius Min', name: 'min_celsius', type: 'number' },
          { label: 'Celsius Max', name: 'max_celsius', type: 'number' },
          { label: 'Fahrenheit Min', name: 'min_fahrenheit', type: 'number' },
          { label: 'Fahrenheit Max', name: 'max_fahrenheit', type: 'number' },
          { label: 'Color', name: 'color', type: 'color' },
          { label: 'Message', name: 'message', type: 'markdown' },
          { label: 'Recommendation', name: 'recommendation', type: 'markdown' },
        ]}
      ></ManageRangeCalculator>
      {Object.keys(errors).length > 0 && (
        <Alert className='mr-3' variant='danger'>
          Failed to save
        </Alert>
      )}

      {saved && (
        <Alert className='mr-3' variant='success'>
          Saved
        </Alert>
      )}
    </Container>
  )
}

export default Temperature
