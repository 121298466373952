import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import UnauthenticatedRoute from 'components/routes/UnauthenticatedRoute'
import Admins from 'containers/admins/Admins'
import CBC from 'containers/calculators/CBC'
import VitaminD from 'containers/calculators/VitaminD'
import VitaminC from 'containers/calculators/VitaminC'
import Oximeter from 'containers/calculators/Oximeter'
import Zinc from 'containers/calculators/Zinc'
import UrinePh from 'containers/calculators/UrinePh'
import BloodPressure from 'containers/calculators/BloodPressure'
import Customers from 'containers/customers/Customers'
import Dashboard from 'containers/dashboard/Dashboard'
import Login from 'containers/login/Login'
import ManageRedBloodCells from 'containers/manage-calculators/RedBloodCells'
import ManageVitaminD from 'containers/manage-calculators/VitaminD'
import ManageVitaminC from 'containers/manage-calculators/VitaminC'
import ManageTemperature from 'containers/manage-calculators/Temperature'
import ManageOximeter from 'containers/manage-calculators/Oximeter'
import ManageZinc from 'containers/manage-calculators/Zinc'
import ManageUrinePh from 'containers/manage-calculators/UrinePh'
import ManageBloodPressure from 'containers/manage-calculators/BloodPressure'
import ManageWhiteBloodCells from 'containers/manage-calculators/WhiteBloodCells'
import ManageTreatments from 'containers/manage-treatments/Treatments'
import NotFound from 'containers/not-found/NotFound'
import PasswordResetEmailForm from 'containers/password-reset/EmailForm'
import PasswordResetPasswordForm from 'containers/password-reset/PasswordForm'
import ReportingCharts from 'containers/reporting-charts/ReportingCharts'
import Temperature from 'containers/calculators/Temperature'
import TreatmentsByCategory from 'containers/treatments-by-category/TreatmentsByCategory'
import Treatments from 'containers/treatments/Treatments'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'

const Routes = () => {
  const { user } = useContext(AuthContext)

  const homeComponent = () => {
    switch (user && user.role) {
      case 'system_user':
        return ManageRedBloodCells
      default:
        return Dashboard
    }
  }

  return (
    <Switch>
      <AuthenticatedRoute path='/' exact component={homeComponent()} />
      <AuthenticatedRoute path='/cbc' roles={['customer']} exact component={CBC} />
      <AuthenticatedRoute
        path='/vitamin-d'
        roles={['customer']}
        exact
        component={VitaminD}
      />

      <AuthenticatedRoute
        path='/vitamin-c'
        roles={['customer']}
        features={['extra_calcs']}
        exact
        component={VitaminC}
      />

      <AuthenticatedRoute
        path='/oximeter'
        roles={['customer']}
        features={['extra_calcs']}
        exact
        component={Oximeter}
      />

      <AuthenticatedRoute
        path='/urine-ph'
        roles={['customer']}
        features={['extra_calcs']}
        exact
        component={UrinePh}
      />

      <AuthenticatedRoute
        path='/temperature'
        roles={['customer']}
        features={['extra_calcs']}
        exact
        component={Temperature}
      />

      <AuthenticatedRoute
        path='/blood-pressure'
        roles={['customer']}
        features={['extra_calcs']}
        exact
        component={BloodPressure}
      />

      <AuthenticatedRoute
        path='/zinc'
        roles={['customer']}
        features={['extra_calcs']}
        exact
        component={Zinc}
      />

      <AuthenticatedRoute
        path='/treatments'
        roles={['customer']}
        exact
        component={Treatments}
      />
      <AuthenticatedRoute
        path='/treatments/:category'
        roles={['customer']}
        exact
        component={TreatmentsByCategory}
      />

      <AuthenticatedRoute
        path='/reporting-charts'
        roles={['customer']}
        features={['analytics']}
        exact
        component={ReportingCharts}
      />

      <AuthenticatedRoute
        path='/manage/white-blood-cells'
        roles={['system_user']}
        exact
        component={ManageWhiteBloodCells}
      />
      <AuthenticatedRoute
        path='/manage/red-blood-cells'
        roles={['system_user']}
        exact
        component={ManageRedBloodCells}
      />
      <AuthenticatedRoute
        path='/manage/vitamin-d'
        roles={['system_user']}
        exact
        component={ManageVitaminD}
      />
      <AuthenticatedRoute
        path='/manage/vitamin-c'
        roles={['system_user']}
        exact
        component={ManageVitaminC}
      />
      <AuthenticatedRoute
        path='/manage/oximeter'
        roles={['system_user']}
        exact
        component={ManageOximeter}
      />
      <AuthenticatedRoute
        path='/manage/temperature'
        roles={['system_user']}
        exact
        component={ManageTemperature}
      />
      <AuthenticatedRoute
        path='/manage/zinc'
        roles={['system_user']}
        exact
        component={ManageZinc}
      />
      <AuthenticatedRoute
        path='/manage/urine-ph'
        roles={['system_user']}
        exact
        component={ManageUrinePh}
      />
      <AuthenticatedRoute
        path='/manage/blood-pressure'
        roles={['system_user']}
        exact
        component={ManageBloodPressure}
      />
      <AuthenticatedRoute
        path='/manage/treatments'
        roles={['system_user']}
        exact
        component={ManageTreatments}
      />
      <AuthenticatedRoute
        path='/manage/customers'
        roles={['system_user']}
        exact
        component={Customers}
      />
      <AuthenticatedRoute
        path='/manage/admins'
        roles={['system_user']}
        exact
        component={Admins}
      />

      <UnauthenticatedRoute
        path='/password/reset'
        exact
        component={PasswordResetEmailForm}
      />
      <UnauthenticatedRoute
        path='/password/reset/:token'
        exact
        component={PasswordResetPasswordForm}
      />

      <UnauthenticatedRoute path='/login' exact component={Login} />

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
