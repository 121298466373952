import { answers, get } from 'api/simple-calculators'
import heroImage from 'assets/oximeter.png'
import FullPageLoader from 'components/FullPageLoader'
import HeroHeader from 'components/HeroHeader'
import Meter from 'components/Meter'
import NumberForm from 'components/number-form/NumberForm'
import RecommendationPanel from 'components/RecommendationPanel'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import GenderSelect from './GenderSelect'
import styles from './Oximeter.module.scss'

const slug = 'oximeter'

function Oximeter() {
  const { user } = useContext(AuthContext)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [question, setQuestion] = useState(null)
  const [result, setResult] = useState(null)

  const [mainError, setMainError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await get(slug)
      setQuestion(res.data)
      setResult(res.data.answer ?? null)
      setInitialLoading(false)
    }
    fetchData()
  }, [])

  const handleSubmit = async (val, unit) => {
    setResult(null)
    setLoading(true)

    const res = await answers(slug, { value: val, unit: unit })
    setLoading(false)
    if (!res.success || res.data.hasOwnProperty('errors')) {
      setMainError('There was error processing your results, please try again.')
    } else {
      setMainError('')

      setResult(res.data)
    }
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return user.gender ? (
    <>
      <Container fluid className='p-0 mt-n5'>
        <HeroHeader src={heroImage} text='Oximeter' />

        <Container className='mb-5'>
          {result && (
            <RecommendationPanel
              text={result.message}
              variant={
                result.range === 'Optimal'
                  ? 'green'
                  : result.range === null
                  ? 'red'
                  : 'yellow'
              }
            />
          )}

          <Row className='align-items-left'>
            <Col>
              {
                <NumberForm
                  label='Enter your O2 result'
                  calculator={question}
                  loading={loading}
                  result={result}
                  recommendationLink={false}
                  units={['%']}
                  onSubmit={handleSubmit}
                />
              }
            </Col>
            <Col>
              <Card className='h-100 p-3 font-weight-bold'>
                <p className='text-success'>Optimal oxygen levels more than 98%</p>
                <p>
                  Standard lab range {`${question.meter_min} - ${question.meter_max}`}%
                </p>
              </Card>
            </Col>
            <Col className='d-none d-xl-block'></Col>
          </Row>

          {result && (
            <Row className='mt-5 mb-5'>
              <Col className='justify-content-center'>
                <Meter
                  min={question.meter_min}
                  max={question.meter_max}
                  inc={question.meter_inc}
                  optimalMin={
                    result && question.ranges.find(range => range.label === 'Optimal').min
                  }
                  optimalMax={
                    question.ranges.find(range => range.label === 'Optimal').max
                  }
                  value={result.value}
                  colorClass='solid'
                  bordered={true}
                />
              </Col>
            </Row>
          )}
        </Container>
      </Container>

      <Container fluid className='background-grey pb-5 pt-5'>
        <Container>
          <Card className='p-4 mb-5'>
            <h6 className='font-weight-bold'>Why monitor your oxygen levels daily?</h6>
            <ReactMarkdown>{question.why}</ReactMarkdown>
          </Card>

          <Meter
            min={question.meter_min}
            max={question.meter_max}
            inc={question.meter_inc}
            bordered={true}
            colorClass='oximeter'
          />
          <div className={styles.meterLabels}>
            <div className={styles.meterLabel + ' ' + styles.below}>Below lab range</div>
            <div className={styles.meterLabel + ' ' + styles.standard}>
              Standard lab range
            </div>
            <div className={styles.meterLabel + ' ' + styles.optimal}>Optimal</div>
          </div>
          <div className='font-weight-light'>
            Anything 94 and below - check with your doctor for a 2nd opinion
          </div>
        </Container>
      </Container>
      <Container className='mt-3'>
        {mainError && (
          <Alert className='mt-4' variant='danger'>
            {mainError}
          </Alert>
        )}
        {result && (
          <>
            <h5>Recommendation</h5>
            <ReactMarkdown className='font-weight-light'>
              {result.recommendation}
            </ReactMarkdown>
          </>
        )}
      </Container>
    </>
  ) : (
    <Container>
      <GenderSelect />
    </Container>
  )
}

export default Oximeter
