import FormControl from 'components/FormControl'
import Loader from 'components/Loader'
import { get, create, update } from 'api/treatments'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'
import smallbanner1 from 'assets/smallbanner1.png'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import FormControlMarkdown from './FormControlMarkdown'
import Tooltip from './Tooltip'
import TreatmentPanel from './TreatmentPanel'

function ManageTreatmentModal({ treatmentId, show, onHide, afterSave }) {
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [values, setValues] = useState()
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true)

      if (treatmentId) {
        const treatment = await get(treatmentId)

        setValues({
          name: treatment.data.name,
          details: treatment.data.details,
          items: treatment.data.items,
        })
      } else {
        setValues({
          name: '',
          details: '',
          items: [],
        })
      }

      setInitialLoading(false)
    }

    fetchData()
  }, [treatmentId])

  const handleTogglePreview = () => setPreview(!preview)

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleChangeTreatmentItem = (index, event) => {
    const { name, value } = event.target

    const newItems = Array.from(values.items)
    newItems[index] = { ...values.items[index], [name]: value }

    setValues({ ...values, items: newItems })
  }

  const handleDeleteTreatmentItem = index => {
    const newItems = Array.from(values.items)
    newItems.splice(index, 1)
    setValues({ ...values, items: newItems })
  }

  const handleAddTreatmentItem = () => {
    const newItems = Array.from(values.items)
    newItems.push({
      id: '',
      category: 'Supplements',
      name: 'New item',
      quantity: '',
      when: '',
      other: '',
      condition: '',
      alert: '',
    })
    setValues({ ...values, items: newItems })
  }

  const handleSubmit = async () => {
    setLoading(true)

    const body = {
      name: values.name,
      details: values.details,
      items: values.items,
    }

    const res = treatmentId ? await update(treatmentId, body) : await create(body)

    if (res.data.hasOwnProperty('errors')) {
      setErrors(res.data.errors)
      setLoading(false)
    } else {
      await afterSave()
      setErrors({})
      setLoading(false)
      onHide()
    }

    setLoading(false)
  }

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {initialLoading ? (
          <Loader />
        ) : (
          <>
            {preview ? (
              <div className='d-flex justify-content-center'>
                <TreatmentPanel treatment={values} backgroundImg={smallbanner1} />
              </div>
            ) : (
              <Form>
                <Form.Group className='mb-4'>
                  <Form.Label>Name</Form.Label>
                  <FormControl
                    name='name'
                    errors={errors}
                    inputProps={{
                      maxLength: 255,
                      value: values.name,
                      onChange: handleChange,
                    }}
                  />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <FormControlMarkdown
                    label='Details'
                    value={values.details}
                    formControlProps={{
                      name: 'details',
                      errors: errors,
                      inputProps: {
                        maxLength: 1000,
                        value: values.details,
                        onChange: handleChange,
                        as: 'textarea',
                        rows: 4,
                      },
                    }}
                  />
                </Form.Group>

                <h5>Treatment Items</h5>
                {values.items.length > 0 ? (
                  <Accordion>
                    {values.items.map((x, index) => (
                      <TreatmentItem
                        key={x.id}
                        index={index}
                        item={x}
                        errors={errors}
                        handleChange={handleChangeTreatmentItem}
                        handleDelete={handleDeleteTreatmentItem}
                      />
                    ))}
                  </Accordion>
                ) : (
                  <i>None Created</i>
                )}

                <div className='pt-4 pb-3 pl-1'>
                  <Button size='sm' onClick={handleAddTreatmentItem}>
                    Add Treatment Item
                  </Button>
                </div>
              </Form>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide} disabled={loading}>
          Close
        </Button>
        <Button variant='secondary' onClick={handleTogglePreview} disabled={loading}>
          {preview ? 'Edit Mode' : 'Preview'}
        </Button>
        <Button variant='primary' onClick={handleSubmit} disabled={loading}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function TreatmentItem({ index, item, errors, handleChange, handleDelete }) {
  return (
    <Card>
      <Card.Header>
        <div className='d-flex'>
          <Accordion.Toggle as={Button} variant='link' eventKey={index + 1}>
            {item.name ? item.name : 'Not Set'}
          </Accordion.Toggle>

          <div className='flex-grow-1'></div>

          <Tooltip label='Delete item'>
            <Button
              variant='danger'
              className='px-2'
              size='sm'
              onClick={() => {
                handleDelete(index)
              }}
            >
              <Trash />
            </Button>
          </Tooltip>
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={index + 1}>
        <Card.Body>
          <Form.Group>
            <Form.Label>Category</Form.Label>
            <FormControl
              name='category'
              errors={errors}
              inputProps={{
                as: 'select',
                value: item.category,
                onChange: event => {
                  handleChange(index, event)
                },
              }}
            >
              <option value='Supplements'>Supplements</option>
              <option value='Nutritional'>Nutritional</option>
            </FormControl>
          </Form.Group>

          <Form.Group>
            <Form.Label>Name</Form.Label>
            <FormControl
              name='name'
              errors={errors}
              inputProps={{
                maxLength: 255,
                placeholder: 'Vitamin C',
                value: item.name,
                onChange: event => {
                  handleChange(index, event)
                },
              }}
            />
          </Form.Group>

          <Form.Group>
            <FormControlMarkdown
              label='Quantity'
              value={item.quantity}
              formControlProps={{
                name: 'quantity',
                errors: errors,
                inputProps: {
                  maxLength: 1000,
                  placeholder: '200mg - 400mg per day',
                  value: item.quantity,
                  onChange: event => {
                    handleChange(index, event)
                  },
                },
              }}
            />
          </Form.Group>

          <Form.Group>
            <FormControlMarkdown
              label='When'
              value={item.when}
              formControlProps={{
                name: 'when',
                errors: errors,
                inputProps: {
                  maxLength: 1000,
                  placeholder: 'With lunch',
                  value: item.when,
                  onChange: event => {
                    handleChange(index, event)
                  },
                },
              }}
            />
          </Form.Group>

          <Form.Group>
            <FormControlMarkdown
              label='General'
              value={item.other}
              formControlProps={{
                name: 'other',
                errors: errors,
                inputProps: {
                  maxLength: 1000,
                  placeholder: 'Check label for exact quantity to take',
                  value: item.other,
                  onChange: event => {
                    handleChange(index, event)
                  },
                },
              }}
            />
          </Form.Group>

          <Form.Group>
            <FormControlMarkdown
              label='Condition'
              value={item.condition}
              formControlProps={{
                name: 'condition',
                errors: errors,
                inputProps: {
                  maxLength: 1000,
                  placeholder: 'Only if bloated',
                  value: item.condition,
                  onChange: event => {
                    handleChange(index, event)
                  },
                },
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Alert Box</Form.Label>
            <FormControl
              value={item.alert}
              name='alert'
              errors={errors}
              errorPrefix={`items.${index}.`}
              inputProps={{
                maxLength: 60,
                placeholder: 'Only if bloated',
                value: item.alert,
                onChange: event => {
                  handleChange(index, event)
                },
              }}
            />
          </Form.Group>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

ManageTreatmentModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  afterSave: PropTypes.func,
}

ManageTreatmentModal.defaultProps = {
  show: false,
  afterSave: () => {},
}

export default ManageTreatmentModal
