import LoaderButton from 'components/LoaderButton'
import PropTypes from 'prop-types'
import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'

function DeleteModal({ show, onHide, deleting, onDelete, errorMessage }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this item?</p>

        {errorMessage && (
          <Alert variant='danger'>There was an error deleting this item.</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={deleting} variant='secondary' onClick={onHide}>
          Cancel
        </Button>
        <LoaderButton
          loading={deleting}
          label='Delete'
          onClick={onDelete}
          variant='danger'
        />
      </Modal.Footer>
    </Modal>
  )
}

DeleteModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  deleting: PropTypes.bool,
  onDelete: PropTypes.func,
  errorMessage: PropTypes.string,
}

DeleteModal.defaultProps = {
  show: false,
  deleting: false,
  errorMessage: '',
}

export default DeleteModal
