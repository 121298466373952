import { get, update } from 'api/calculator-questions'
import { list } from 'api/treatments'
import FormControl from 'components/FormControl'
import LoaderButton from 'components/LoaderButton'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Loader from './Loader'

function EditCalculatorQuestion({ questionSlug, show, onHide, afterSave }) {
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [question, setQuestion] = useState()
  const [values, setValues] = useState()
  const [treatments, setTreatments] = useState([])
  const [lowTreatments, setLowTreatments] = useState()
  const [highTreatments, setHighTreatments] = useState()

  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true)

      const [question, treats] = await Promise.all([get(questionSlug), list()])

      setQuestion(question.data)

      setValues({
        female_optimal_min: question.data.female_optimal_min,
        female_optimal_max: question.data.female_optimal_max,
        male_optimal_min: question.data.male_optimal_min,
        male_optimal_max: question.data.male_optimal_max,
        female_standard_min: question.data.female_standard_min,
        female_standard_max: question.data.female_standard_max,
        male_standard_min: question.data.male_standard_min,
        male_standard_max: question.data.male_standard_max,
        high_clinical_implications: question.data.high_clinical_implications,
        low_clinical_implications: question.data.low_clinical_implications,
      })

      setLowTreatments(question.data.low_treatments)
      setHighTreatments(question.data.high_treatments)

      setTreatments(treats.data.map(x => ({ id: x.id, name: x.name })))
      setInitialLoading(false)
    }

    fetchData()
  }, [questionSlug])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async () => {
    setLoading(true)
    const res = await update(question.slug, {
      female_optimal_min: values.female_optimal_min,
      female_optimal_max: values.female_optimal_max,
      male_optimal_min: values.male_optimal_min,
      male_optimal_max: values.male_optimal_max,
      female_standard_min: values.female_standard_min,
      female_standard_max: values.female_standard_max,
      male_standard_min: values.male_standard_min,
      male_standard_max: values.male_standard_max,
      high_clinical_implications: values.high_clinical_implications,
      low_clinical_implications: values.low_clinical_implications,
      high_treatments: highTreatments.map(x => x.id),
      low_treatments: lowTreatments.map(x => x.id),
    })

    if (res.data.hasOwnProperty('errors')) {
      setErrors(res.data.errors)
      setLoading(false)
    } else {
      await afterSave()
      setLoading(false)
      onHide()
    }
  }

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{question ? question.question : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {initialLoading ? (
          <Loader />
        ) : (
          <Form>
            <div className='d-flex'>
              <Form.Group className='mb-4 mr-2'>
                <Form.Label>♀ Optimal Min</Form.Label>
                <FormControl
                  name='female_optimal_min'
                  errors={errors}
                  inputProps={{
                    value: values.female_optimal_min,
                    onChange: handleChange,
                  }}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <Form.Label>♀ Optimal Max</Form.Label>
                <FormControl
                  name='female_optimal_max'
                  errors={errors}
                  inputProps={{
                    value: values.female_optimal_max,
                    onChange: handleChange,
                  }}
                />
              </Form.Group>
            </div>

            <div className='d-flex'>
              <Form.Group className='mb-4 mr-2'>
                <Form.Label>♂ Optimal Min</Form.Label>
                <FormControl
                  name='male_optimal_min'
                  errors={errors}
                  inputProps={{
                    value: values.male_optimal_min,
                    onChange: handleChange,
                  }}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <Form.Label>♂ Optimal Max</Form.Label>
                <FormControl
                  name='male_optimal_max'
                  errors={errors}
                  inputProps={{
                    value: values.male_optimal_max,
                    onChange: handleChange,
                  }}
                />
              </Form.Group>
            </div>

            <div className='d-flex'>
              <Form.Group className='mb-4 mr-2'>
                <Form.Label>♀ Standard Min</Form.Label>
                <FormControl
                  name='female_standard_min'
                  errors={errors}
                  inputProps={{
                    value: values.female_standard_min,
                    onChange: handleChange,
                  }}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <Form.Label>♀ Standard Max</Form.Label>
                <FormControl
                  name='female_standard_max'
                  errors={errors}
                  inputProps={{
                    value: values.female_standard_max,
                    onChange: handleChange,
                  }}
                />
              </Form.Group>
            </div>

            <div className='d-flex'>
              <Form.Group className='mb-4 mr-2'>
                <Form.Label>♂ Standard Min</Form.Label>
                <FormControl
                  name='male_standard_min'
                  errors={errors}
                  inputProps={{ value: values.male_standard_min, onChange: handleChange }}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <Form.Label>♂ Standard Max</Form.Label>
                <FormControl
                  name='male_standard_max'
                  errors={errors}
                  inputProps={{ value: values.male_standard_max, onChange: handleChange }}
                />
              </Form.Group>
            </div>

            <Form.Group className='mb-4'>
              <Form.Label>High Clinical Implications</Form.Label>
              <FormControl
                name='high_clinical_implications'
                errors={errors}
                inputProps={{
                  maxLength: 255,
                  value: values.high_clinical_implications,
                  onChange: handleChange,
                  as: 'textarea',
                  rows: 3,
                }}
              />
            </Form.Group>

            <Form.Group className='mb-4'>
              <Form.Label>Low Clinical Implications</Form.Label>
              <FormControl
                name='low_clinical_implications'
                errors={errors}
                inputProps={{
                  maxLength: 255,
                  value: values.low_clinical_implications,
                  onChange: handleChange,
                  as: 'textarea',
                  rows: 3,
                }}
              />
            </Form.Group>

            <Form.Group style={{ marginTop: '20px' }}>
              <Form.Label>High Treatments</Form.Label>
              <Typeahead
                id='high-treatments'
                labelKey='name'
                multiple
                onChange={setHighTreatments}
                options={treatments}
                placeholder='Choose several treatments...'
                selected={highTreatments}
              />
            </Form.Group>

            <Form.Group style={{ marginTop: '20px' }}>
              <Form.Label>Low Treatments</Form.Label>
              <Typeahead
                id='low-treatments'
                labelKey='name'
                multiple
                onChange={setLowTreatments}
                options={treatments}
                placeholder='Choose several treatments...'
                selected={lowTreatments}
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide} disabled={loading}>
          Close
        </Button>
        <LoaderButton
          label='Save'
          loading={loading}
          variant='primary'
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default EditCalculatorQuestion
