import React from 'react'
import BsPagination from 'react-bootstrap/Pagination'

function Pagination({ meta, handleChangePage }) {
  return (
    <div className='d-flex justify-content-center mt-3'>
      <BsPagination>
        <BsPagination.First
          disabled={meta.current_page === 1}
          onClick={() => {
            handleChangePage(1)
          }}
        />

        <BsPagination.Prev
          disabled={meta.current_page === 1}
          onClick={() => {
            handleChangePage(meta.current_page - 1)
          }}
        />

        <BsPagination.Item active className='text-center'>
          Page {meta.current_page} of {meta.last_page}
        </BsPagination.Item>

        <BsPagination.Next
          disabled={meta.current_page === meta.last_page}
          onClick={() => {
            handleChangePage(meta.current_page + 1)
          }}
        />

        <BsPagination.Last
          disabled={meta.current_page === meta.last_page}
          onClick={() => {
            handleChangePage(meta.last_page)
          }}
        />
      </BsPagination>
    </div>
  )
}

export default Pagination
