import React from 'react'
import styles from './RecommendationPanel.module.scss'

function RecommendationPanel({ text, variant }) {
  return (
    <div className={'recommendationPanelContainer'}>
      <div
        className={'recommendationPanel text-white font-weight-bold ' + styles[variant]}
      >
        {text}
      </div>
    </div>
  )
}

export default RecommendationPanel
