import { answers, get } from 'api/simple-calculators'
import heroImage from 'assets/temperature.png'
import FullPageLoader from 'components/FullPageLoader'
import HeroHeader from 'components/HeroHeader'
import Meter from 'components/Meter'
import NumberForm from 'components/number-form/NumberForm'
import RecommendationPanel from 'components/RecommendationPanel'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import GenderSelect from './GenderSelect'
import styles from './Temperature.module.scss'

const slug = 'temperature'

function Temperature() {
  const { user } = useContext(AuthContext)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [question, setQuestion] = useState(null)
  const [result, setResult] = useState(null)
  const [unit, setUnit] = useState('celsius')

  const [mainError, setMainError] = useState('')

  const celsiusLabels = [
    { value: 35, text: 'Burn out / Adrenal issue / Hyperthyroid' },
    { value: 36, text: 'Slightly low / Adrenal concern / Hyperthyroid' },
    { value: 37, text: 'Optimal' },
    { value: 38, text: 'Low grade fever' },
    { value: 39, text: 'High grade fever - Call to inform your doctor' },
    { value: 40, text: 'Critical fever - Medical emergency' },
  ]

  const fahrenheitLabels = [
    { value: 35, text: 'Burn out / Adrenal issue / Hyperthyroid' },
    { value: 36, text: 'Slightly low / Adrenal concern / Hyperthyroid' },
    { value: 37, text: 'Optimal' },
    { value: 38, text: 'Low grade fever' },
    { value: 39, text: 'High grade fever - Call to inform your doctor' },
    { value: 40, text: 'Critical fever - Medical emergency' },
  ]

  useEffect(() => {
    const fetchData = async () => {
      const res = await get(slug)
      setQuestion(res.data)
      setResult(res.data.answer ?? null)
      setInitialLoading(false)
    }
    fetchData()
  }, [])

  const handleSubmit = async (val, unit) => {
    setResult(null)
    setUnit(unit)
    setLoading(true)

    const res = await answers(slug, { value: val, unit: unit })
    setLoading(false)
    if (!res.success || res.data.hasOwnProperty('errors')) {
      setMainError('There was error processing your results, please try again.')
    } else {
      setMainError('')
      setResult(res.data)
    }
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return user.gender ? (
    <>
      <Container fluid className='p-0 mt-n5'>
        <HeroHeader src={heroImage} text='Temperature' />

        <Container className='mb-5'>
          {result && <RecommendationPanel text={result.message} variant={result.color} />}

          <Row>
            <Col>
              <NumberForm
                label='Enter temperature'
                calculator={question}
                loading={loading}
                result={result}
                recommendationLink={false}
                units={['celsius', 'fahrenheit']}
                onSubmit={handleSubmit}
              />
            </Col>
            <Col>
              <Card className='h-100 p-3 font-weight-bold'>
                <p className='text-success'>Optimal temperature 37</p>
              </Card>
            </Col>
            <Col></Col>
          </Row>

          {result && (
            <Row className='mt-5 mb-5'>
              <Col className='justify-content-center'>
                <Meter
                  min={question['meter_min_' + unit]}
                  max={question['meter_max_' + unit]}
                  inc={question['meter_inc_' + unit]}
                  optimalMin={
                    result &&
                    question.ranges.find(range => range.label === 'Optimal')[
                      'min_' + unit
                    ]
                  }
                  optimalMax={
                    question.ranges.find(range => range.label === 'Optimal')[
                      'max_' + unit
                    ]
                  }
                  value={result.value}
                  colorClass='solid'
                  bordered={true}
                />
              </Col>
            </Row>
          )}

          {mainError && (
            <Alert className='mt-4' variant='danger'>
              {mainError}
            </Alert>
          )}
        </Container>
      </Container>

      <Container fluid className='background-grey pt-3 pb-3'>
        <Container>
          {result?.range === 'Critical' ? (
            <Card className='p-5 m-5 bg-red text-center'>
              <h2 className='font-weight-bold'>
                Call your emergency services immediately
              </h2>
              <p>Your temperature is too high</p>
            </Card>
          ) : (
            <Row className='mt-5 mb-5'>
              <Col className='justify-content-center'>
                <h6>Why should you monitor your temperature daily?</h6>
                <ReactMarkdown className='font-weight-light'>
                  {question.why}
                </ReactMarkdown>
              </Col>
            </Row>
          )}

          {unit === 'celsius' ? (
            <Meter
              min={question['meter_min_' + unit]}
              max={question['meter_max_' + unit]}
              inc={question['meter_inc_' + unit]}
              colorClass='temperature'
              bordered={true}
              labels={celsiusLabels.map(l => ({
                value: l.value,
                text: <div className={styles.temperatureMeterLabel}>{l.text}</div>,
              }))}
            ></Meter>
          ) : (
            <Meter
              min={question['meter_min_' + unit]}
              max={question['meter_max_' + unit]}
              inc={question['meter_inc_' + unit]}
              colorClass='temperature'
              bordered={true}
              labels={fahrenheitLabels.map(l => ({
                value: l.value,
                text: <div className={styles.temperatureMeterLabel}>{l.text}</div>,
              }))}
            ></Meter>
          )}
        </Container>
      </Container>
      <Container className='mt-5'>
        {result && (
          <>
            <h5>Recommendation</h5>
            <ReactMarkdown className='font-weight-light'>
              {result.recommendation}
            </ReactMarkdown>
          </>
        )}
      </Container>
    </>
  ) : (
    <Container>
      <GenderSelect />
    </Container>
  )
}

export default Temperature
