import { get, update } from 'api/simple-calculators'
import FullPageLoader from 'components/FullPageLoader'
import LoaderButton from 'components/LoaderButton'
import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

const slug = 'zinc'

function Zinc() {
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [values, setValues] = useState()
  const [errors, setErrors] = useState({})
  const [saved, setSaved] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true)

      const res = await get(slug)

      setValues(res.data)
      setInitialLoading(false)
    }

    fetchData()
  }, [])

  const handleSubmit = async () => {
    setLoading(true)
    setErrors({})
    setSaved(false)

    const res = await update(slug, values)

    if (res.data.hasOwnProperty('errors')) {
      setErrors(res.data.errors)
      setLoading(false)
    } else {
      setErrors({})
      setLoading(false)
      setSaved(true)
    }
  }

  const handleChange = e => {
    const { name, value } = e.target

    setValues(values => ({
      ...values,
      [name]: value,
    }))
  }

  const handleOptionChange = (name, value, index) => {
    setValues(values => ({
      ...values,
      options: values.options.map((x, i) => (i === index ? { ...x, [name]: value } : x)),
    }))
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return (
    <Container>
      <h1 className='mb-4'>Zinc Calculator</h1>

      <Form>
        <Form.Group>
          <Form.Label>Why is zinc an essential mineral?</Form.Label>
          <Form.Control
            name={`why`}
            type='text'
            value={values.why}
            onChange={handleChange}
          ></Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Question</Form.Label>
          <Form.Control
            name={`question`}
            type='text'
            value={values.question}
            onChange={handleChange}
          ></Form.Control>
        </Form.Group>

        {values.options.map((a, aIndex) => (
          <div key={`answer-${aIndex}`}>
            <h4>Option {aIndex + 1}</h4>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>Answer Text</Form.Label>
                  <Form.Control
                    name={`options[${aIndex}}]text`}
                    type='text'
                    value={values.options[aIndex]?.text}
                    onChange={e => handleOptionChange('text', e.target.value, aIndex)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>Answer Recommendation</Form.Label>
                  <Form.Control
                    name={`options[${aIndex}}]recommendation`}
                    type='text'
                    value={values.options[aIndex]?.recommendation}
                    onChange={e =>
                      handleOptionChange('recommendation', e.target.value, aIndex)
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>Answer Message</Form.Label>
                  <Form.Control
                    name={`options[${aIndex}}]message`}
                    type='text'
                    value={values.options[aIndex]?.message}
                    onChange={e => handleOptionChange('message', e.target.value, aIndex)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>Answer Color</Form.Label>
                  <Form.Control
                    name={`options[${aIndex}]color`}
                    value={values.options[aIndex]?.color}
                    onChange={e => handleOptionChange('color', e.target.value, aIndex)}
                    as='select'
                  >
                    <option value='red'>Red</option>
                    <option value='yellow'>Yellow</option>
                    <option value='green'>Green</option>
                    <option value='blue'>Blue</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </div>
        ))}

        <div className='mt-4 d-flex justify-content-end'>
          {Object.keys(errors).length > 0 && (
            <Alert className='mr-3' variant='danger'>
              Failed to save
            </Alert>
          )}

          {saved && (
            <Alert className='mr-3' variant='success'>
              Saved
            </Alert>
          )}

          <div>
            <LoaderButton
              label='Save'
              type='submit'
              loading={loading}
              variant='primary'
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Form>
    </Container>
  )
}

export default Zinc
