import { logout } from 'api/auth'
import admins from 'assets/admins.png'
import analytics from 'assets/analytics.png'
import customers from 'assets/customers.png'
import nutritional from 'assets/nutritional.png'
import rbc from 'assets/rbc.svg'
import supplements from 'assets/supplements.svg'
import treatments from 'assets/treatments.svg'
import calculator from 'assets/calculator.png'
import vitd from 'assets/vitamind.png'
import wbc from 'assets/wbc.svg'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link, NavLink } from 'react-router-dom'
import styles from './Auth.module.scss'

const icons = {
  CBC: rbc,
  'Vitamin D': vitd,
  'Red blood cells': rbc,
  'White blood cells': wbc,
  Treatments: treatments,
  Supplements: supplements,
  Nutritional: nutritional,
  Analytics: analytics,
  Customers: customers,
  Admins: admins,
  Zinc: calculator,
  VitaminC: calculator,
  Oximeter: calculator,
  'Urine PH': calculator,
  Temperature: calculator,
  'Blood Pressue': calculator,
  Calculators: calculator,
}

const customerMenuItems = {
  calculators: {
    label: 'Calculators',
    to: '#',
    items: [
      { label: 'CBC', to: '/cbc' },
      { label: 'Vitamin D', to: '/vitamin-d' },
      { label: 'Zinc', to: '/zinc', feature: 'extra_calcs' },
      { label: 'Vitamin C', to: '/vitamin-c', feature: 'extra_calcs' },
      { label: 'Oximeter', to: '/oximeter', feature: 'extra_calcs' },
      { label: 'Urine PH', to: '/urine-ph', feature: 'extra_calcs' },
      { label: 'Temperature', to: '/temperature', feature: 'extra_calcs' },
      { label: 'Blood Pressue', to: '/blood-pressure', feature: 'extra_calcs' },
    ],
  },
  treatments: { label: 'Treatments', to: '/treatments', items: [] },
  analytics: {
    label: 'Analytics',
    to: '/reporting-charts',
    items: [],
    feature: 'analytics',
  },
}

const systemUserMenuItems = {
  rbc: { label: 'Red blood cells', to: '/manage/red-blood-cells', items: [] },
  wbc: { label: 'White blood cells', to: '/manage/white-blood-cells', items: [] },
  treatments: { label: 'Treatments', to: '/manage/treatments', items: [] },
  customers: { label: 'Customers', to: '/manage/customers', items: [] },
  admins: { label: 'Admins', to: '/manage/admins', items: [] },
  calculators: {
    label: 'Calculators',
    to: '#',
    items: [
      { label: 'Vitamin D', to: '/manage/vitamin-d' },
      { label: 'Zinc', to: '/manage/zinc' },
      { label: 'Vitamin C', to: '/manage/vitamin-c' },
      { label: 'Oximeter', to: '/manage/oximeter' },
      { label: 'Urine PH', to: '/manage/urine-ph' },
      { label: 'Temperature', to: '/manage/temperature' },
      { label: 'Blood Pressue', to: '/manage/blood-pressure' },
    ],
  },
}

function Layout({ component: C, ...props }) {
  const { user, setAuthenticated, setUser } = useContext(AuthContext)
  const [openSubItemMenu, setOpenSubItemMenu] = useState(null)

  const getIcon = key => {
    return icons.hasOwnProperty(key) ? icons[key] : ''
  }

  const getCustomerMenuItems = () => {
    const items = customerMenuItems

    user.additional_nav.map(x => {
      return (items[x] = {
        icon: getIcon(x),
        label: x,
        to: `/treatments/${x.toLowerCase()}`,
        items: [],
      })
    })

    return items
  }

  const getMenuItems = () => {
    return user.role === 'system_user'
      ? Object.values(systemUserMenuItems)
      : Object.values(getCustomerMenuItems())
  }

  const handleLogout = async () => {
    setAuthenticated(false)
    if (await logout()) {
      setAuthenticated(false)
      setUser(null)
    }
  }

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={`sidenav ${styles.sidenav}`}>
          <div className={styles.sidenavItems}>
            {getMenuItems()
              .sort(
                (a, b) =>
                  Object.keys(icons).indexOf(a.label) >
                  Object.keys(icons).indexOf(b.label)
              )
              .filter(
                x =>
                  !x.hasOwnProperty('feature') ||
                  user.features.includes(x.feature) ||
                  user.role === 'system_user'
              )
              .map(x =>
                x.items.length === 0 ? (
                  <NavLink key={x.label} className={styles.sidenavItem} to={x.to} exact>
                    <div className='d-flex'>
                      <div className={styles.sidenavIcon}>
                        <img src={getIcon(x.label)} alt={x.label} />
                      </div>{' '}
                      {x.label}
                    </div>
                  </NavLink>
                ) : (
                  <div key={x.label}>
                    <Nav.Item
                      className={styles.sidenavItem}
                      onClick={() =>
                        setOpenSubItemMenu(openSubItemMenu =>
                          openSubItemMenu === x.label ? null : x.label
                        )
                      }
                    >
                      <div className='d-flex'>
                        <div className={styles.sidenavIcon}>
                          <img src={getIcon(x.label)} alt={x.label} />
                        </div>{' '}
                        {x.label}
                      </div>
                    </Nav.Item>
                    <div
                      className={
                        styles.sidenavSubMenu +
                        (openSubItemMenu === x.label ? ` ${styles.open}` : '')
                      }
                    >
                      {x.items
                        .filter(
                          x =>
                            !x.hasOwnProperty('feature') ||
                            user.features.includes(x.feature) ||
                            user.role === 'system_user'
                        )
                        .map(y => (
                          <NavLink
                            to={y.to}
                            key={y.label}
                            className={styles.sidenavSubItem}
                          >
                            {y.label}
                          </NavLink>
                        ))}
                    </div>
                  </div>
                )
              )}
          </div>
        </div>

        <Navbar
          height='1000px'
          variant='dark'
          sticky='top'
          expand='xl'
          className={styles.navbar}
          collapseOnSelect
        >
          <Container fluid>
            <Navbar.Brand as={Link} to='/'>
              <img className='d-none d-xl-block' src='/logo-2.png' alt='Logo' />
              <img width='260' className='d-xl-none' src='/logo-white.png' alt='Logo' />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse id='navbar'>
              <Nav className={`${styles.items} ml-auto`}>
                {getMenuItems()
                  .sort(
                    (a, b) =>
                      Object.keys(icons).indexOf(a.label) >
                      Object.keys(icons).indexOf(b.label)
                  )
                  .filter(
                    x =>
                      !x.hasOwnProperty('feature') ||
                      user.features.includes(x.feature) ||
                      user.role === 'system_user'
                  )
                  .map(x =>
                    x.items.length === 0 ? (
                      <Nav.Link
                        key={x.label}
                        eventKey={x.label}
                        className='d-block d-xl-none'
                        as={NavLink}
                        to={x.to}
                        exact
                      >
                        {x.label}
                      </Nav.Link>
                    ) : (
                      <div key={x.label}>
                        <Nav.Item
                          className='d-block d-xl-none nav-link cursor-pointer'
                          onClick={() =>
                            setOpenSubItemMenu(openSubItemMenu =>
                              openSubItemMenu === x.label ? null : x.label
                            )
                          }
                        >
                          <div className='d-flex'>{x.label}</div>
                        </Nav.Item>
                        <div
                          className={
                            styles.mobileNavSubmenu +
                            ' d-xl-none d-block' +
                            (openSubItemMenu === x.label ? ` ${styles.open}` : '')
                          }
                        >
                          {x.items
                            .filter(
                              x =>
                                !x.hasOwnProperty('feature') ||
                                user.features.includes(x.feature) ||
                                user.role === 'system_user'
                            )
                            .map(y => (
                              <NavLink
                                to={y.to}
                                key={y.label}
                                className='pl-4 d-block nav-link'
                              >
                                {y.label}
                              </NavLink>
                            ))}
                        </div>
                      </div>
                    )
                  )}

                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className={styles.content}>
          <Container fluid className='pt-5 pb-5 pl-0 pr-0'>
            <C {...props} />
          </Container>
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          <a
            href='https://www.leveluphealth.co.uk/terms-and-conditions'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Use
          </a>
        </div>
        <div>
          <a
            href='https://www.leveluphealth.co.uk/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  )
}

export default Layout
