import { get, list } from 'api/user-treatments'
import banner2 from 'assets/banner2.png'
import banner3 from 'assets/banner3.png'
import smallbanner1 from 'assets/smallbanner1.png'
import smallbanner2 from 'assets/smallbanner2.png'
import FullPageLoader from 'components/FullPageLoader'
import Panel from 'components/panel/Panel'
import SelfResponsible from 'components/SelfResponsible'
import TreatmentPanel from 'components/TreatmentPanel'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import styles from './Treatments.module.scss'

const banners = [smallbanner1, smallbanner2, banner2, banner3]

function Treatments() {
  const [treatments, setTreatments] = useState()
  const [hasAnswered, setHasAnswered] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const _summary = await list()
      const _treatments = await Promise.all(_summary.data.map(x => get(x.id)))

      setHasAnswered(_summary.meta.has_answered)
      setTreatments(_treatments)
    }

    fetchData()
  }, [])

  if (!treatments) {
    return <FullPageLoader />
  }

  return (
    <Container>
      {treatments.length > 0 ? (
        <>
          <SelfResponsible alertProps={{ className: 'mb-4' }} />
          <div className={styles.grid}>
            {treatments.map((x, index) => (
              <TreatmentPanel
                key={x.data.name}
                treatment={x.data}
                backgroundImg={banners[index % banners.length]}
              />
            ))}
          </div>
        </>
      ) : (
        <Panel backgroundImg={banner2}>
          <Panel className={styles.noTreats}>
            {hasAnswered ? (
              <>
                <div className='mb-3'>
                  Consider maintaining a healthy lifestyle, exercise and organic foodplan
                  as well as acts of daily kindness such as yoga or meditation.
                </div>
                <div>
                  To achieve optimal results, be consistent in your practice and your good
                  health will follow.
                </div>
              </>
            ) : (
              <>
                We haven't currently got any treatment recommendations for you. Enter your
                CBC results to check for any new treatment recommendations.
              </>
            )}
          </Panel>
        </Panel>
      )}
    </Container>
  )
}

export default Treatments
