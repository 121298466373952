import config from 'config'

export async function list() {
  const result = await fetch(config.API_URL + '/iapi/user-treatments')
  return await result.json()
}

export async function get(treatmentId) {
  const result = await fetch(config.API_URL + `/iapi/user-treatments/t/${treatmentId}`)
  return await result.json()
}

export async function listCategoryItems(category) {
  const result = await fetch(config.API_URL + `/iapi/user-treatments/c/${category}`)
  return await result.json()
}
