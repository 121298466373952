import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import ReactMarkdown from 'react-markdown'
import FormControl from './FormControl'
import Tooltip from 'components/Tooltip'
import { PencilFill, EyeFill } from 'react-bootstrap-icons'

function FormControlMarkdown({ formControlProps, label, value }) {
  const [preview, setPreview] = useState(false)

  const togglePreview = () => {
    setPreview(!preview)
  }

  return (
    <>
      <Form.Label>
        {label}
        <Tooltip label={preview ? 'Edit' : 'Preview'}>
          <span onClick={togglePreview} className='cursor-pointer ml-2'>
            {preview ? <PencilFill /> : <EyeFill />}
          </span>
        </Tooltip>
      </Form.Label>

      {preview ? (
        <div className='m-2'>
          <ReactMarkdown>{value}</ReactMarkdown>
        </div>
      ) : (
        <FormControl {...formControlProps} />
      )}
    </>
  )
}

export default FormControlMarkdown
