import React from 'react'
import { useEffect, useState } from 'react'
import styles from './Meter.Module.scss'

function Meter({
  min = 0,
  max = 100,
  value,
  inc = 5,
  optimalMin = 50,
  optimalMax = 75,
  colorClass = 'vitaminC',
  labels = [],
}) {
  const [steps, setSteps] = useState([])

  useEffect(() => {
    setSteps([])
    for (let i = min; i <= max; i += inc) {
      const step = {
        value: i,
        optimal: i >= optimalMin && i <= optimalMax,
        is_first_optimal: i === optimalMin,
        is_last_optimal: i === optimalMax,
        selected: value < i + inc && value >= i,
      }
      setSteps(steps => [...steps, step])
    }
  }, [min, max, inc, setSteps, optimalMax, optimalMin, value])

  const getLabel = value => {
    return labels.find(l => l.value === value) ?? null
  }

  return (
    <div
      className={`${styles.meterContainer} ${
        (labels.length > 0 || (value >= min && value <= max)) && styles.hasValue
      }`}
    >
      <div className={`d-flex flex-row ${styles.meter} ${styles[colorClass]}`}>
        {steps.map((x, i) => (
          <div key={`increment-${i}`} className={`${styles.meterIncrement}`}>
            {x.optimal && (
              <div
                className={`${styles.incrementInner} ${x.optimal && styles.optimal} ${
                  x.is_first_optimal && styles.firstOptimal
                } ${x.is_last_optimal && styles.lastOptimal} ${
                  x.selected && styles.selected
                }`}
              ></div>
            )}
            <span>{x.value}</span>

            {x.selected && (
              <div
                className={styles.valueArrow}
                style={{ transform: `translateX(${100 * ((value - x.value) / inc)}%)` }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20.19'
                  height='44.5'
                  viewBox='0 0 20.19 64.5'
                >
                  <path
                    id='Icon_awesome-long-arrow-alt-down'
                    data-name='Icon awesome-long-arrow-alt-down'
                    d='M15.021,21.551V65.023c0,.954-.493,1.728-1.1,1.728H8.786c-.608,0-1.1-.774-1.1-1.728V21.551H3.463c-1.96,0-2.942-3.722-1.556-5.9L9.8,3.262a1.7,1.7,0,0,1,3.112,0l7.89,12.39c1.386,2.177.4,5.9-1.556,5.9H15.021Z'
                    transform='translate(-1.258 -2.25)'
                  />
                </svg>
                <div>You are here</div>
              </div>
            )}

            {getLabel(x.value) && (
              <div className={styles.label}>{getLabel(x.value).text}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Meter
