import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import config from 'config'
import LogRocket from 'logrocket'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import App from './App'
import AuthContext from './contexts/AuthContext'
import './index.scss'
import * as serviceWorker from './serviceWorker'

require('dotenv').config()

if (config.SENTRY_DSN) {
  Sentry.init({
    environment: config.env,
    dsn: config.SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['warn', 'error'],
      }),
    ],
    tracesSampler: samplingContext => {
      if (samplingContext.transactionContext.name === '/number-porting') {
        return 1
      } else {
        return 0.2
      }
    },
  })
}

if (config.LOG_ROCKET) {
  LogRocket.init(config.LOG_ROCKET, {
    dom: {
      textSanitizer: config.env === 'production' ? true : false,
      inputSanitizer: config.env === 'production' ? true : false,
    },

    network: {
      requestSanitizer: request => {
        request.headers['Cookie'] = ''
        request.headers['X-XSRF-TOKEN'] = ''
        request.body = null
        return request
      },

      responseSanitizer: response => {
        response.headers['set-cookie'] = ''
        response.body = null
        return response
      },
    },
  })
}

const bsAlertToast = ({ appearance, children }) => (
  <Alert className='px-5' variant={appearance}>
    {children}
  </Alert>
)

ReactDOM.render(
  <Router>
    <AuthContext>
      <Sentry.ErrorBoundary fallback={'An error has occurred'}>
        <ToastProvider
          components={{ Toast: bsAlertToast }}
          autoDismiss={true}
          placement='bottom-center'
        >
          <App />
        </ToastProvider>
      </Sentry.ErrorBoundary>
    </AuthContext>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
