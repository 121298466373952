import React from 'react'
import styles from './Panel.module.scss'

function Panel({ children, className, backgroundImg, ...props }) {
  return (
    <div
      className={`${styles.panel} ${className ? className : ''}`}
      style={{ backgroundImage: backgroundImg ? `url("${backgroundImg}")` : '' }}
      {...props}
    >
      {children}
    </div>
  )
}

export default Panel
