import { list } from 'api/treatments'
import FullPageLoader from 'components/FullPageLoader'
import Panel from 'components/panel/Panel'
import Tooltip from 'components/Tooltip'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Pencil } from 'react-bootstrap-icons'
import Table from 'react-bootstrap/Table'
import ManageTreatmentModal from './ManageTreatmentModal'

function ManageTreatments({ slug }) {
  const [treatments, setTreatments] = useState()
  const [show, setShow] = useState(false)
  const [targetTreatment, setTargetTreatment] = useState()

  const handleClose = () => setShow(false)

  const handleShowCreate = () => {
    setTargetTreatment()
    setShow(true)
  }

  const handleShow = treatment => {
    setTargetTreatment(treatment.id)
    setShow(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await list()
      setTreatments(res)
    }

    fetchData()
  }, [])

  if (!treatments) {
    return <FullPageLoader />
  }

  return (
    <>
      <Tooltip label='Create a new customer'>
        <Button className='mb-3' variant='primary' onClick={handleShowCreate}>
          Create
        </Button>
      </Tooltip>

      <Panel className='p-4'>
        <Table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {treatments.data.map((x, index) => (
              <tr key={index}>
                <td>{x.name}</td>
                <td align='right' style={{ minWidth: '135px' }}>
                  <Tooltip label='Edit treatment'>
                    <Button
                      className='mr-1 px-2'
                      variant='secondary'
                      size='sm'
                      onClick={() => handleShow(x)}
                    >
                      <Pencil />
                    </Button>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <ManageTreatmentModal
          treatmentId={targetTreatment}
          show={show}
          onHide={handleClose}
          afterSave={async () => {
            setTreatments(await list())
          }}
        />
      </Panel>
    </>
  )
}

export default ManageTreatments
