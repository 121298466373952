import config from 'config'
import { getJsonHeaders } from './helpers'

export async function list() {
  const result = await fetch(config.API_URL + '/iapi/treatments')
  return await result.json()
}

export async function get(id) {
  const result = await fetch(config.API_URL + `/iapi/treatments/${id}`)
  return await result.json()
}

export async function update(id, body) {
  const res = await fetch(config.API_URL + `/iapi/treatments/${id}`, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}

export async function create(body) {
  const res = await fetch(config.API_URL + '/iapi/treatments', {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}
