import LoaderButton from 'components/LoaderButton'
import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import styles from './ChoiceForm.module.scss'

function ChoiceForm({ question, options, result, loading, onSubmit }) {
  const [value, setValue] = useState(null)

  return (
    <div className={`${styles.choiceForm} p-3 text-white`}>
      <p className='mb-3'>{question}</p>

      <Form
        onSubmit={e => {
          e.preventDefault()
          onSubmit(value)
        }}
      >
        <Row className={styles.options}>
          {options.map((a, i) => (
            <Col key={`option-${i}`} className='mb-3' xs={6}>
              <div
                className={styles.option + (value === i ? ` ${styles.selected}` : '')}
                onClick={() => {
                  setValue(i)
                }}
              >
                {a.text}
              </div>
            </Col>
          ))}
        </Row>

        <Row>
          <Col>
            <LoaderButton type='submit' label='Submit' loading={loading} />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ChoiceForm
