import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import CustomerDashboard from './CustomerDashboard'
import SystemUserDashboard from './SystemUserDashboard'

function Dashboard() {
  const { user } = useContext(AuthContext)

  return user.role === 'system_user' ? <SystemUserDashboard /> : <CustomerDashboard />
}

export default Dashboard
