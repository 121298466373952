import { answers, list } from 'api/calculator-questions'
import calc1 from 'assets/calc1.png'
import calc2 from 'assets/calc2.png'
import congrats from 'assets/congrats.png'
import smallbanner3 from 'assets/smallbanner3.png'
import CBCCalculator from 'components/CBCCalculator'
import FullPageLoader from 'components/FullPageLoader'
import LoaderButton from 'components/LoaderButton'
import Panel from 'components/panel/Panel'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom'
import styles from './CBC.module.scss'
import GenderSelect from './GenderSelect'

function CBC() {
  const { user, reload } = useContext(AuthContext)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [displayForm, setDisplayForm] = useState(true)

  const [absToPercent, setAbsToPercent] = useState('')
  const [lToDl, setLtoDl] = useState('')

  const [rbcQuestions, setRbcQuestions] = useState(false)
  const [rbcValues, setRbcValues] = useState({})

  const [wbcQuestions, setWbcQuestions] = useState(false)
  const [wbcValues, setWbcValues] = useState({})

  const [errors, setErrors] = useState({})
  const [mainError, setMainError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const [_rbc, _wbc] = await Promise.all([list('rbc'), list('wbc')])
      setRbcQuestions(_rbc)
      setWbcQuestions(_wbc)
      setDisplayForm(!_rbc.meta.has_answered || !_wbc.meta.has_answered)
      setInitialLoading(false)
    }

    fetchData()
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    const res = await answers({ ...rbcValues, ...wbcValues })
    if (!res.success || res.data.hasOwnProperty('errors')) {
      if (res.data.hasOwnProperty('errors')) {
        setErrors(res.data.errors)
        setMainError('')
      } else {
        setMainError({})
        setMainError('There was error processing your results, please try again.')
      }
    } else {
      const [_rbc, _wbc] = await Promise.all([list('rbc'), list('wbc'), reload()])
      setRbcQuestions(_rbc)
      setWbcQuestions(_wbc)
      setDisplayForm(!_rbc.meta.has_answered || !_wbc.meta.has_answered)
      setErrors({})
      setMainError('')
    }

    setLoading(false)
  }

  const hasTreatments = () => {
    return rbcQuestions.meta.has_treatments || wbcQuestions.meta.has_treatments
  }

  const handleConvertAbsToPercent = event => {
    const wbc = wbcValues.twbc
    const abs = event.target.value
    const res = (abs / wbc) * 100
    setAbsToPercent(roundNumber(res))
  }

  const handleConvertLtoDl = event => {
    setLtoDl(roundNumber(event.target.value / 10))
  }

  const roundNumber = num => {
    return Math.round(num * 100) / 100
  }

  const renderAnswers = () => {
    return (
      <>
        {hasTreatments() ? (
          <Panel className={`${styles.panelBlue} mb-5`} backgroundImg={smallbanner3}>
            <h2 className={styles.title}>Your Results</h2>

            <div>
              <div>
                <div className='mb-4'>
                  Based on your provided results we have identified certain treatments
                  that we recommend for you.
                </div>

                <div className='d-flex flex-column flex-sm-row'>
                  <Button
                    variant='secondary'
                    as={Link}
                    to='/treatments'
                    className='w-100 mr-sm-3 mb-3 py-3'
                  >
                    Go To My Treatments
                  </Button>

                  <Button
                    variant='secondary'
                    className='w-100 mb-3 py-3'
                    onClick={setDisplayForm}
                  >
                    Enter New Results
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
        ) : (
          <Panel className={`${styles.panelCongrats} mb-5`} backgroundImg={congrats}>
            <h2>Congratulations</h2>

            <div>
              Your results show that you are within the optimal ranges.
              <div>
                <Button
                  variant='secondary'
                  className='mt-5 p-2 px-4'
                  size='sm'
                  onClick={setDisplayForm}
                >
                  Enter New Results
                </Button>
              </div>
            </div>
          </Panel>
        )}

        {(rbcQuestions.meta.clinical_implications.length > 0 ||
          wbcQuestions.meta.clinical_implications.length > 0) && (
          <Panel className={styles.panelBlue}>
            <div>
              <div className={styles.title}>Possible Clinical Implications</div>

              <p className='mb-4'>
                <i>
                  It is important to note that these interpretations are merely guidelines
                  on a potential path to follow back to health.  If any of your blood
                  numbers fall outside of the Standard Lab Ranges, or if you are uncertain
                  of the clinical indications of your results, then we would encourage you
                  to reach out to your MD/DO/ND to seek further guidance.
                </i>
              </p>

              <ClinicalImplications items={rbcQuestions.meta.clinical_implications} />
              <ClinicalImplications items={wbcQuestions.meta.clinical_implications} />
            </div>
          </Panel>
        )}
      </>
    )
  }

  const renderForm = () => {
    return (
      <>
        <Panel className={`${styles.panelBlue} mb-4`}>
          <div>
            <div className={styles.title}>Next Steps</div>
            <div className='mb-3'>
              Enter your blood scores and click submit below to receive your
              comprehensive guide to your suggested treatments, supplements and
              nutritional options for your programme. 
            </div>
            <div>
              For you to achieve maximum benefit while on this programme and afterwards,
              we encourage you to fully immerse yourself in the suggestions laid out for
              you
            </div>
          </div>
        </Panel>

        <Form onSubmit={handleSubmit}>
          <div className={styles.grid}>
            <Panel className={`${styles.panelBlue}`}>
              <div>
                <div className={styles.title}>RBC g/L to g/dL Converter</div>
                <div className='mb-3'>
                  Red blood results below should use the g/dL unit where applicable, if
                  your results show a value using g/L you can convert it by entering it
                  below.
                </div>

                <div className='d-flex'>
                  <div className='flex-grow-1'>
                    <Form.Control
                      name='l_to_dl_converter'
                      placeholder='Value to convert'
                      maxLength={4}
                      onChange={handleConvertLtoDl}
                    />
                  </div>
                  <div className='ml-3' style={{ fontSize: '1.5rem', fontWeight: '900' }}>
                    {lToDl ? ` = ${lToDl}` : ''}
                  </div>
                </div>
              </div>
            </Panel>

            <Panel className={`${styles.panelBlue}`}>
              <div>
                <div className={styles.title}>WBC Absolute to % Converter</div>
                <div className='mb-3'>
                  The white blood values below require % values (except for WBC). If your
                  results give absolute numbers you can use this converter.
                </div>

                {wbcValues.twbc ? (
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <Form.Control
                        name='abs_to_percent'
                        placeholder='Value to convert to percentage'
                        maxLength={4}
                        onChange={handleConvertAbsToPercent}
                      />
                    </div>
                    <div
                      className='ml-3'
                      style={{ fontSize: '1.5rem', fontWeight: '900' }}
                    >
                      {absToPercent ? ` = ${absToPercent}%` : ''}
                    </div>
                  </div>
                ) : (
                  <div>
                    <i>Enter a WBC value below to allow converting values.</i>
                  </div>
                )}
              </div>
            </Panel>

            <CBCCalculator
              questions={rbcQuestions}
              values={rbcValues}
              errors={errors}
              setValues={setRbcValues}
              title='Red Blood Cells'
              backgroundImg={calc1}
            />

            <CBCCalculator
              questions={wbcQuestions}
              values={wbcValues}
              errors={errors}
              setValues={setWbcValues}
              title='White Blood Cells'
              backgroundImg={calc2}
            />
          </div>

          {mainError && (
            <Alert className='mt-4' variant='danger'>
              {mainError}
            </Alert>
          )}

          {!initialLoading && (
            <div className='text-right'>
              <LoaderButton
                label='Calculate'
                className={`${styles.lightOrangeButton} mt-5`}
                loading={loading}
                variant='primary'
                size='lg'
                type='submit'
              />
            </div>
          )}
        </Form>
      </>
    )
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return (
    <Container>
      {user.gender ? (
        <>{displayForm ? renderForm() : renderAnswers()}</>
      ) : (
        <GenderSelect />
      )}
    </Container>
  )
}

function ClinicalImplications({ items }) {
  return (
    <>
      {items.map((x, index) => (
        <div className='mb-3' key={index}>
          <div className='mb-2'>
            <strong>{x.question}</strong>

            <table>
              <tbody>
                <tr>
                  <td className='pr-3'>Standard Lab Range</td>
                  <td>
                    {x.standard_min} - {x.standard_max}
                  </td>
                </tr>
                <tr>
                  <td>Optimal Range</td>
                  <td>
                    {x.optimal_min} - {x.optimal_max}
                  </td>
                </tr>
                <tr>
                  <td>You Answered</td>
                  <td>{x.answer_value}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {x.clinical_implications}
        </div>
      ))}
    </>
  )
}

export default CBC
