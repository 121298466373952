import { me } from 'api/auth'
import React, { useEffect, useState } from 'react'

const AuthContext = React.createContext()
const WithAuth = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [authenticating, setAuthenticating] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const user = await me()
      if (user) {
        setAuthenticated(true)
        setUser(user)
      }
      setAuthenticating(false)
    }

    fetchData()
  }, [])

  const reload = async () => {
    const user = await me()
    if (user) {
      setUser(user)
    }
  }

  const defaultContext = {
    authenticating,
    authenticated,
    setAuthenticated,
    user,
    setUser,
    reload,
  }
  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>
}

export { AuthContext }
export default WithAuth
