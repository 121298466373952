import LoaderButton from 'components/LoaderButton'
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import styles from './NumberForm.module.scss'

function NumberForm({ label, calculator, result, loading, units, onSubmit }) {
  const [value, setValue] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(units[0])

  useEffect(() => {
    setValue(result?.value ?? null)
    setSelectedUnit(result?.unit ?? units[0])
  }, [setValue, result, setSelectedUnit, units])

  return (
    <div className={`${styles.numberForm} p-3`}>
      <label className='text-white' htmlFor='vitamin-c'>
        {label}
      </label>
      <Form
        onSubmit={e => {
          e.preventDefault()
          onSubmit(value, selectedUnit)
        }}
      >
        <Row>
          <Col>
            <input
              type='number'
              step='any'
              value={value ?? ''}
              min={calculator.meter_min}
              max={calculator.meter_max}
              onChange={e => {
                setValue(e.target.value)
              }}
              name='number-value'
              className={result?.color && `bg-${result.color}`}
            />
            {units.length > 1 &&
              units.map((unit, index) => (
                <Form.Check
                  key={`unit-${index}`}
                  label={unit.charAt(0).toUpperCase() + unit.slice(1)}
                  type='radio'
                  checked={unit === selectedUnit}
                  id={`unit-${index}`}
                  onChange={() => setSelectedUnit(unit)}
                />
              ))}
          </Col>

          <Col>
            <LoaderButton
              type='submit'
              label='Submit'
              loading={loading}
              className='btn-sm card-shadow'
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default NumberForm
