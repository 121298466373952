import ManageCalculator from 'components/ManageCalculator'
import React from 'react'
import { Container } from 'react-bootstrap'

function WhiteBloodCells() {
  return (
    <Container>
      <h1 className='mb-4'>Red Blood Cells Calculator</h1>

      <ManageCalculator slug='rbc' />
    </Container>
  )
}

export default WhiteBloodCells
