import { list } from 'api/calculator-questions'
import EditCalculatorQuestion from 'components/EditCalculatorQuestion'
import FullPageLoader from 'components/FullPageLoader'
import Panel from 'components/panel/Panel'
import Tooltip from 'components/Tooltip'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Pencil } from 'react-bootstrap-icons'
import Table from 'react-bootstrap/Table'

function ManageCalculator({ slug }) {
  const [questions, setQuestions] = useState()
  const [targetQuestion, setTargetQuestion] = useState()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
    setTargetQuestion()
  }

  const handleShow = question => {
    setTargetQuestion(question)
    setShow(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await list(slug)
      setQuestions(res)
    }

    fetchData()
  }, [slug])

  if (!questions) {
    return <FullPageLoader />
  }

  return (
    <Panel className='p-4'>
      <Table responsive>
        <thead>
          <tr>
            <th>Question</th>
            <th>♀ Optimal</th>
            <th>♂ Optimal</th>
            <th>♀ Standard</th>
            <th>♂ Standard</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {questions.data.map(x => (
            <tr key={x.slug}>
              <td>{x.question}</td>
              <td>
                {x.female_optimal_min} - {x.female_optimal_max}
              </td>
              <td>
                {x.male_optimal_min} - {x.male_optimal_max}
              </td>
              <td>
                {x.female_standard_min} - {x.female_standard_max}
              </td>
              <td>
                {x.male_standard_min} - {x.male_standard_max}
              </td>
              <td align='right' style={{ minWidth: '135px' }}>
                <Tooltip label='Edit values'>
                  <Button
                    className='mr-1 px-2'
                    variant='secondary'
                    size='sm'
                    onClick={() => handleShow(x)}
                  >
                    <Pencil />
                  </Button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {targetQuestion && (
        <EditCalculatorQuestion
          show={show}
          onHide={handleClose}
          questionSlug={targetQuestion.slug}
          afterSave={async () => {
            setQuestions(await list(slug))
          }}
        />
      )}
    </Panel>
  )
}

export default ManageCalculator
