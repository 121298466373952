import { login, me } from 'api/auth'
import LoaderButton from 'components/LoaderButton'
import Logo from 'components/logo/Logo'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import styles from './Login.module.scss'

function Login(props) {
  const { setAuthenticated, setUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [messageSeverity, setMessageSeverity] = useState('info')

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value, invalid: false } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (validateForm()) {
      setLoading(true)
      const result = await login(values.email.value, values.password.value)
      if (result.success) {
        setUser(await me())
        setAuthenticated(true)
        setLoading(false)
      } else {
        setMessage('Invalid username or password')
        setMessageSeverity('primary')
        setLoading(false)
      }
    }
  }

  const validateForm = () => {
    let valid = true
    let newValues = values

    if (values.email.value.length <= 0) {
      newValues = { ...newValues, email: { value: values.email.value, invalid: true } }
      valid = false
    }

    if (values.password.value.length <= 0) {
      newValues = {
        ...newValues,
        password: { value: values.password.value, invalid: true },
      }
      valid = false
    }

    setValues(newValues)
    return valid
  }

  const [values, setValues] = useState({
    email: { value: '', invalid: false },
    password: { value: '', invalid: false },
    remember: 1,
  })

  return (
    <Container fluid className='h-100'>
      <Row className={styles.row}>
        <Col lg={6} className={styles.logoContainer}>
          <Logo />
        </Col>

        <Col lg={6} className={styles.formContainer}>
          <div>
            <h3 className='mb-3'>
              <strong>Login</strong>
            </h3>
            <Form className={styles.form} onSubmit={handleSubmit}>
              <Form.Group className='mb-4'>
                <Form.Group>
                  <Form.Control
                    isInvalid={values.email.invalid}
                    type='email'
                    name='email'
                    placeholder='Email address'
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    isInvalid={values.password.invalid}
                    type='password'
                    name='password'
                    placeholder='Password'
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Group>

              <div>
                <LoaderButton
                  label='Login'
                  className='mb-4'
                  loading={loading}
                  variant='primary'
                  size='lg'
                  type='submit'
                />
              </div>

              <Link to='/password/reset'>Forgot password</Link>

              <Alert
                style={{ visibility: message ? 'visible' : 'hidden' }}
                className='mt-4'
                variant={messageSeverity}
              >
                {message}
              </Alert>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
