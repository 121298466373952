import config from 'config'
import { getJsonHeaders } from './helpers'

export async function get(slug) {
  const result = await fetch(config.API_URL + `/iapi/sc/${slug}`)
  return await result.json()
}

export async function update(slug, body) {
  const res = await fetch(config.API_URL + `/iapi/sc/${slug}`, {
    method: 'PUT',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}

export async function answers(slug, body) {
  let url = config.API_URL + `/iapi/sc/${slug}/answers`
  const res = await fetch(url, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    data: await res.json(),
  }
}
