import { answers, get } from 'api/simple-calculators'
import heroImage from 'assets/bloodpressure.png'
import MeterImage from 'assets/bp-meter.png'
import FullPageLoader from 'components/FullPageLoader'
import HeroHeader from 'components/HeroHeader'
import LoaderButton from 'components/LoaderButton'
import RecommendationPanel from 'components/RecommendationPanel'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Card, Col, Container, Form, Row } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import styles from './BloodPressure.module.scss'
import GenderSelect from './GenderSelect'
import arrowRight from 'assets/bp-arrow-right.svg'
import arrowLeft from 'assets/bp-arrow-left.svg'
import smile from 'assets/bp-smile.svg'

const slug = 'bloodpressure'

function BloodPressure() {
  const { user } = useContext(AuthContext)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [question, setQuestion] = useState(null)
  const [result, setResult] = useState(null)

  const [systolicValue, setSystolicValue] = useState(null)
  const [diastolicValue, setDiastolicValue] = useState(null)

  const [mainError, setMainError] = useState('')

  const [topValue, setTopValue] = useState(null)
  const [lowerValue, setLowerValue] = useState(null)

  const topScale = [170, 160, 150, 140, 130, 120, 110, 100, 90, 80, 70, 60, 50]
  const lowerScale = [170, 160, 150, 140, 130, 120, 110, 100, 90, 80, 70, 60, 50]

  useEffect(() => {
    const fetchData = async () => {
      const res = await get(slug)
      setQuestion(res.data)
      setResult(res.data.answer ?? null)
      setInitialLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (result) {
      const [systolic, diastolic] = result.value.split('|')
      setTopValue(systolic)
      setLowerValue(diastolic)
      setDiastolicValue(diastolic)
      setSystolicValue(systolic)
    }
  }, [result])

  const handleSubmit = async e => {
    e.preventDefault()
    setResult(null)
    setLoading(true)

    const res = await answers(slug, {
      systolic: systolicValue,
      diastolic: diastolicValue,
    })
    setLoading(false)
    if (!res.success || res.data.hasOwnProperty('errors')) {
      setMainError('There was error processing your results, please try again.')
    } else {
      setMainError('')

      setResult(res.data)
    }
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return user.gender ? (
    <>
      <Container fluid className='p-0 mt-n5'>
        <HeroHeader src={heroImage} text='Blood Pressure' />

        <Container className='mb-4'>
          {result && <RecommendationPanel text={result.message} variant={result.color} />}

          {mainError && (
            <Alert className='mt-4' variant='danger'>
              {mainError}
            </Alert>
          )}

          <Row className='align-items-left'>
            <Col lg={5} className='mb-lg-2'>
              <Form onSubmit={handleSubmit}>
                <div className={styles.numberForm + ' p-3 mb-2'}>
                  <label className='text-white mb-0' htmlFor='systolic'>
                    Enter your Systolic pressure
                  </label>
                  <p className={styles.labelHelp}>
                    <strong>Top figure</strong> representing the pressure on the arteries
                    when the heart contracts and pumps blood.
                  </p>
                  <input
                    type='number'
                    value={systolicValue ?? ''}
                    onChange={e => {
                      setSystolicValue(e.target.value)
                    }}
                    name='systolic'
                  />
                </div>
                <div className={styles.numberForm + ' p-3 mb-2'}>
                  <label className='text-white mb-0' htmlFor='systolic'>
                    Enter your Diastolic pressure
                  </label>
                  <p className={styles.labelHelp}>
                    <strong>Lower figure</strong> representing the pressure on the
                    arteries when the heart rests between beats.
                  </p>
                  <input
                    type='number'
                    value={diastolicValue ?? ''}
                    onChange={e => {
                      setDiastolicValue(e.target.value)
                    }}
                    name='diastolic'
                  />
                </div>
                <LoaderButton
                  type='submit'
                  className='mt-2 mb-2 card-shadow'
                  label='Submit'
                  loading={loading}
                />
              </Form>
            </Col>
            <Col lg={4}>
              <Card className='p-4 font-weight-bold card-shadow'>
                <p className='text-success'>Optimal Range 120/80</p>
                <p>Standard Lab Range 90/60 - 120/80</p>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className='background-grey pb-5 pt-5'>
        <Container>
          <Row>
            <Col className='justify-content-center'>
              <h6 className='font-weight-bold'>
                Why should you monitor your blood pressure daily?
              </h6>
              <ReactMarkdown className='font-weight-light'>{question.why}</ReactMarkdown>
            </Col>
          </Row>

          {result && (
            <>
              <div className={styles.bloodPressureContainer}>
                <p className='mt-4 ml-5 mb-3 mr-5'>
                  <strong>Top figure</strong> representing the pressure on the arteries
                  when the heart contracts and pumps blood.
                </p>
                <div className={styles.bloodPressureScale}>
                  {topScale.map(x => (
                    <div key={`meter_top_${x}`} className={styles.scaleNotch}>
                      <div className={styles.bloodPressureScaleValue}>{x}</div>
                    </div>
                  ))}
                  <div className={styles.resultValueContainer}>
                    <div className={styles.resultValueContainerInner}>
                      <span
                        className={styles.resultValue}
                        style={{
                          right: `calc(100% / 120 * ${topValue - 50})`,
                        }}
                      >
                        {topValue}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.bloodPressureMeter}>
                  <img
                    src={MeterImage}
                    alt='Blood pressure meter'
                    width='100%'
                    height='210px'
                  />
                </div>
                <div className={styles.bloodPressureScale}>
                  {lowerScale.map(x => (
                    <div key={`meter_lower_${x}`} className={styles.scaleNotch}>
                      <div className={styles.bloodPressureScaleValue}>{x}</div>
                    </div>
                  ))}
                  <div className={styles.resultValueContainer}>
                    <div className={styles.resultValueContainerInner}>
                      <span
                        className={styles.resultValue + ' ' + styles[result.label]}
                        style={{
                          right: `calc(100% / 120 * ${lowerValue - 50})`,
                        }}
                      >
                        {lowerValue}
                      </span>
                    </div>
                  </div>
                </div>
                <p className='mr-5 ml-5 mt-4 mb-4'>
                  <strong>Lower figure</strong> representing the pressure on the arteries
                  when the heart rests between beats.
                </p>
                <Row className='mr-sm-5 ml-sm-5'>
                  <Col className='mb-4'>
                    <h5 className={styles.red + ' font-weight-bold'}>
                      High B.P = Hypertension
                    </h5>
                    <p>Inform your Doctor, medication may be required.</p>
                  </Col>
                  <Col className='mb-4'>
                    <h5 className={styles.yellow + ' font-weight-bold'}>
                      Raised B.P = Mild Hypertension
                    </h5>
                  </Col>
                  <Col className='mb-4'>
                    <h5 className={styles.green + ' font-weight-bold'}>
                      Good B.P = Normal
                    </h5>
                  </Col>
                  <Col className='mb-4'>
                    <h5 className={styles.blue + ' font-weight-bold'}>
                      Low B.P = Hypotension
                    </h5>
                  </Col>
                </Row>
                <div
                  className={`${styles.yinYangContainer} d-flex flex-row align-items-center justify-content-center`}
                >
                  <div className={`${styles.yinYang} text-red mr-2 font-weight-bold`}>
                    Yang
                  </div>
                  <img
                    src={arrowRight}
                    className={`mr-2 ${styles.yinYangArrow}`}
                    alt='right arrow'
                  />
                  <img width={60} height={60} src={smile} alt='smile' />
                  <img
                    src={arrowLeft}
                    className={`ml-2 ${styles.yinYangArrow}`}
                    alt='left arrow'
                  />
                  <div className={`${styles.yinYang} text-blue ml-2 font-weight-bold`}>
                    Yin
                  </div>
                </div>
              </div>
            </>
          )}
        </Container>
      </Container>

      <Container className='mt-3 p-4'>
        {result && (
          <>
            <h5>Recommendation</h5>
            <ReactMarkdown className='font-weight-light'>
              {result.recommendation}
            </ReactMarkdown>
          </>
        )}
      </Container>
    </>
  ) : (
    <Container>
      <GenderSelect />
    </Container>
  )
}

export default BloodPressure
