import { resetPasswordEmail } from 'api/auth'
import Logo from 'components/logo/Logo'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import LoaderButton from '../../components/LoaderButton'
import styles from './EmailForm.module.scss'

function EmailForm() {
  const [loading, setLoading] = useState(false)
  const [hideForm, setHideForm] = useState(false)
  const [messages, setMessages] = useState([])
  const [messagesSeverity, setMessagesSeverity] = useState('info')

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const result = await resetPasswordEmail(values.email)
    if (result.success) {
      setLoading(false)
      setMessages([['', 'We have emailed your password reset link']])
      setMessagesSeverity('success')
      setHideForm(true)
    } else {
      setMessages(result.errors)
      setMessagesSeverity('danger')
      setLoading(false)
    }
  }

  const [values, setValues] = useState({
    email: { value: '', invalid: false },
  })

  return (
    <Container fluid className='h-100'>
      <Row className={styles.row}>
        <Col lg={6} className={styles.logoContainer}>
          <Logo large />
        </Col>

        <Col lg={6} className={styles.formContainer}>
          <div>
            {!hideForm && (
              <Form className={styles.form} onSubmit={handleSubmit}>
                <p>Enter your email address below to reset your password.</p>
                <Form.Group className='mb-4'>
                  <Form.Control
                    isInvalid={values.email.invalid}
                    type='email'
                    name='email'
                    placeholder='Email address'
                    onChange={handleChange}
                  />
                </Form.Group>

                <LoaderButton
                  label='Submit'
                  className='mb-4'
                  loading={loading}
                  variant='primary'
                  size='lg'
                  type='submit'
                />
              </Form>
            )}

            <Link to='/login'>Back to login</Link>

            {messages.length > 0 && (
              <Alert className='mt-4' variant={messagesSeverity}>
                {messages.map((message, index) => (
                  <div key={index}>{message[1]}</div>
                ))}
              </Alert>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default EmailForm
