import { deleteUser, list } from 'api/admins'
import DeleteModal from 'components/DeleteModal'
import FullPageLoader from 'components/FullPageLoader'
import ManageAdminModal from 'components/ManageAdminModal'
import Pagination from 'components/Pagination'
import Panel from 'components/panel/Panel'
import Tooltip from 'components/Tooltip'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Pencil, Trash } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import { AuthContext } from 'contexts/AuthContext'

function Admins() {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIndex, setDeleteIndex] = useState('')
  const [deleteError, setDeleteError] = useState('')
  const [manageAdminModalOpen, setManageAdminModalOpen] = useState(false)
  const [targetAdmin, setTargetAdmin] = useState()

  const handleCloseCreate = () => setManageAdminModalOpen(false)

  const handleShowCreate = () => {
    setTargetAdmin()
    setManageAdminModalOpen(true)
  }

  const handleShowEdit = admin => {
    setTargetAdmin(admin)
    setManageAdminModalOpen(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await list(15, 1)
      setUsers(res)
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleOpenDeleteDialog = (id, index) => {
    setDeleteId(id)
    setDeleteIndex(index)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async (id, index) => {
    setDeleteError(false)
    setDeleting(true)
    const success = await deleteUser(id)

    if (success) {
      const res = await list(15, 1)
      setUsers(res)
      setDeleteOpen(false)
    } else {
      setDeleteError(true)
    }

    setDeleting(false)
  }

  const handleChangePage = async page => {
    setLoading(true)
    setUsers(await list(15, page))
    setLoading(false)
  }

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <Container>
      <h1>Admins</h1>
      <Tooltip label='Create a new admin'>
        <Button className='mb-3' variant='primary' onClick={handleShowCreate}>
          Create
        </Button>
      </Tooltip>

      <Panel className='p-4'>
        {users.data.length === 0 ? (
          <div>No admins created yet.</div>
        ) : (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.data.map((x, index) => (
                  <tr key={x.id}>
                    <td>
                      {x.first_name} {x.last_name}
                    </td>
                    <td>{x.email}</td>
                    <td>
                      {x.created.diff} ({x.created.datetime})
                    </td>
                    <td align='right' style={{ minWidth: '135px' }}>
                      <Tooltip label='Edit admin'>
                        <Button
                          className='mr-1 px-2'
                          variant='secondary'
                          size='sm'
                          onClick={() => {
                            handleShowEdit(x)
                          }}
                        >
                          <Pencil />
                        </Button>
                      </Tooltip>
                      {x.id === user.id ? (
                        <Tooltip label='You are currently logged in as this admin'>
                          <Button variant='danger' className='px-2' size='sm' disabled>
                            <Trash />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip label='Delete admin'>
                          <Button
                            variant='danger'
                            className='px-2'
                            size='sm'
                            onClick={() => handleOpenDeleteDialog(x.id, index)}
                          >
                            <Trash />
                          </Button>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Pagination meta={users.meta} handleChangePage={handleChangePage} />
          </>
        )}
      </Panel>

      <DeleteModal
        show={deleteOpen}
        onHide={handleCloseDeleteDialog}
        deleting={deleting}
        onDelete={() => handleDelete(deleteId, deleteIndex)}
        errorMessage={deleteError}
      />

      <ManageAdminModal
        admin={targetAdmin}
        show={manageAdminModalOpen}
        onHide={handleCloseCreate}
        afterSave={async () => {
          setUsers(await list())
        }}
      />
    </Container>
  )
}

export default Admins
