import PropTypes from 'prop-types'
import React from 'react'
import styles from './ProgressBar.module.scss'

function ProgressBar({
  percentageDisplay,
  text,
  percentage,
  textWidth,
  variant,
  children,
}) {
  const circleClassName = `circle-${variant}`
  const barClassName = `bar-${variant}`

  return (
    <div className={styles.container}>
      <div className={`${styles.circle} ${styles[circleClassName]}`}>
        {percentageDisplay}
      </div>
      <div className='d-flex h-100'>
        <div className={`${styles.barText} ${styles[barClassName]}`}>
          <div
            className='d-flex align-items-center h-100 pl-5'
            style={{ width: `${textWidth}` }}
          >
            {text}
          </div>
        </div>
        <div className='w-100'>
          <div
            className={`${styles.bar} ${styles[barClassName]}`}
            style={{ minWidth: '18px', width: `${percentage}%` }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  percentageDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  percentage: PropTypes.number,
  textWidth: PropTypes.string,
  variant: PropTypes.oneOf(['green', 'orange', 'red']),
}

ProgressBar.defaultProps = {
  percentageDisplay: '',
  text: '',
  percentage: 100,
  textWidth: '0px',
  variant: 'green',
}

export default ProgressBar
