import { me, resetPassword } from 'api/auth'
import Logo from 'components/logo/Logo'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { useParams } from 'react-router-dom'
import LoaderButton from '../../components/LoaderButton'
import styles from './PasswordForm.module.scss'

function PasswordForm(props) {
  const { token } = useParams()
  const { setAuthenticated, setUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState()
  const [errors, setErrors] = useState([])

  useEffect(() => {
    setEmail(new URLSearchParams(props.location.search).get('email'))
  }, [setLoading, props.location])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const queryParams = new URLSearchParams(props.location.search)

    setLoading(true)
    const result = await resetPassword(
      token,
      email,
      values.password,
      queryParams.get('welcome') ?? ''
    )
    if (result.success) {
      setUser(await me())
      setAuthenticated(true)
      setLoading(false)
      props.history.push({
        pathname: '/',
        state: {
          severity: 'success',
          message: 'Password successfully reset',
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const [values, setValues] = useState({
    password: '',
  })

  return (
    <Container fluid className='h-100'>
      <Row className={styles.row}>
        <Col lg={6} className={styles.logoContainer}>
          <Logo large />
        </Col>

        <Col lg={6} className={styles.formContainer}>
          <Form className={styles.form} onSubmit={handleSubmit}>
            <p>Please enter your new password below.</p>
            <Form.Group className='mb-4'>
              <Form.Control
                isInvalid={values.password.invalid}
                type='password'
                name='password'
                placeholder='New Password'
                onChange={handleChange}
              />
            </Form.Group>
            <LoaderButton
              label='Submit'
              className='mb-4'
              loading={loading}
              variant='primary'
              size='lg'
              type='submit'
            />

            {errors.length > 0 && (
              <Alert className='mt-4' variant='danger'>
                {errors.map((error, index) => (
                  <div key={index}>{error[1][0]}</div>
                ))}
              </Alert>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default PasswordForm
