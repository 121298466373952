import { answers, get } from 'api/simple-calculators'
import heroImage from 'assets/urineph.png'
import FullPageLoader from 'components/FullPageLoader'
import HeroHeader from 'components/HeroHeader'
import Meter from 'components/Meter'
import NumberForm from 'components/number-form/NumberForm'
import RecommendationPanel from 'components/RecommendationPanel'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import styles from './UrinePh.module.scss'
import GenderSelect from './GenderSelect'
import arrow from 'assets/arrow.svg'

const slug = 'urineph'

function UrinePh() {
  const { user } = useContext(AuthContext)

  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)

  const [question, setQuestion] = useState(null)
  const [result, setResult] = useState(null)

  const [mainError, setMainError] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await get(slug)
      setQuestion(res.data)
      setResult(res.data.answer ?? null)
      setInitialLoading(false)
    }
    fetchData()
  }, [])

  const handleSubmit = async (val, unit) => {
    setResult(null)
    setLoading(true)

    const res = await answers(slug, { value: val, unit: unit })
    setLoading(false)
    if (!res.success || res.data.hasOwnProperty('errors')) {
      setMainError('There was error processing your results, please try again.')
    } else {
      setMainError('')

      setResult(res.data)
    }
  }

  if (initialLoading) {
    return <FullPageLoader />
  }

  return user.gender ? (
    <>
      <Container fluid className='p-0 mt-n5'>
        <HeroHeader src={heroImage} text='Urine Ph' />

        <Container className='mb-5'>
          {result && <RecommendationPanel text={result.message} variant={result.color} />}

          {mainError && (
            <Alert className='mt-4' variant='danger'>
              {mainError}
            </Alert>
          )}

          <Row className='align-items-left'>
            <Col>
              <NumberForm
                label='Enter your PH result'
                calculator={question}
                loading={loading}
                result={result}
                recommendationLink={false}
                units={['%']}
                onSubmit={handleSubmit}
              />
            </Col>
            <Col>
              <Card className='p-3 font-weight-bold card-shadow'>
                <p className='text-success'>Optimal range 6.0 - 7.0</p>
                <p>
                  Standard lab range {`${question.meter_min} - ${question.meter_max}%`}
                </p>
              </Card>
            </Col>
            <Col className='d-none d-xl-block'></Col>
          </Row>

          {result && (
            <Row className='mt-5 mb-5'>
              <Col className='justify-content-center'>
                <Meter
                  min={question.meter_min}
                  max={question.meter_max}
                  inc={question.meter_inc}
                  optimalMin={6}
                  optimalMax={7}
                  value={result.value}
                  colorClass='solid'
                  bordered={false}
                />
              </Col>
            </Row>
          )}
        </Container>
      </Container>
      <Container fluid className='background-grey pb-5 pt-5'>
        <Container className='mb-3'>
          <Row className='mb-5'>
            <Col className='justify-content-center'>
              <h6 className='font-weight-bold'>Why should you monitor your pH daily?</h6>
              <p className='font-weight-light'>{question.why}</p>
            </Col>
          </Row>

          <Meter
            min={4.5}
            max={9}
            inc={0.5}
            colorClass='phDisplay'
            bordered={false}
            labels={[
              { value: 4.5, text: 'Acid' },
              {
                value: 6.0,
                text: (
                  <>
                    <img alt='arrow' width={16} height={16} src={arrow} />

                    <div className={styles.urinePhLabel}>
                      For results lower than 6, consider Vitamin D
                    </div>
                  </>
                ),
              },
              { value: 7, text: 'Neutral' },
              {
                value: 7.5,
                text: (
                  <>
                    <img
                      alt='arrow'
                      width={16}
                      height={16}
                      style={{ transform: 'rotate(180deg)' }}
                      src={arrow}
                    />

                    <div className={styles.urinePhLabel}>
                      For results higher than 7, consider taking Vitamin C
                    </div>
                  </>
                ),
              },
              { value: 9, text: 'Alkaline' },
            ]}
          />
        </Container>
      </Container>
      <Container className='mt-3'>
        {result && (
          <>
            <h5>Recommendation</h5>
            <ReactMarkdown className='font-weight-light'>
              {result.recommendation}
            </ReactMarkdown>
          </>
        )}
      </Container>
    </>
  ) : (
    <Container>
      <GenderSelect />
    </Container>
  )
}

export default UrinePh
